import React, { useState } from "react";
import Modal from "./Modal"; // Assicurati di importare correttamente il tuo componente Modal
import { Link } from "react-router-dom";

const Footer = () => {
  const [isBatteryRegulationsModalOpen, setIsBatteryRegulationsModalOpen] =
    useState(false);
  const [isReturnPolicyModalOpen, setIsReturnPolicyModalOpen] = useState(false);
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
    useState(false);
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);
  const [isCopyrightModalOpen, setIsCopyrightModalOpen] = useState(false);
  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);

  const openBatteryRegulationsModal = () => {
    setIsBatteryRegulationsModalOpen(true);
  };

  const openReturnPolicyModal = () => {
    setIsReturnPolicyModalOpen(true);
  };

  const openTermsAndConditionsModal = () => {
    setIsTermsAndConditionsModalOpen(true);
  };

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const openCopyrightModal = () => {
    setIsCopyrightModalOpen(true);
  };

  const openCookiesModal = () => {
    setIsCookiesModalOpen(true);
  };

  const closeModal = () => {
    setIsBatteryRegulationsModalOpen(false);
    setIsReturnPolicyModalOpen(false);
    setIsTermsAndConditionsModalOpen(false);
    setIsPrivacyPolicyModalOpen(false);
    setIsCopyrightModalOpen(false);
    setIsCookiesModalOpen(false);
  };

  const contentCopyright = `
  Questo sito è protetto da diritti di proprietà intellettuale ed è destinato all'uso personale o aziendale dei nostri clienti. L'uso non autorizzato o improprio del contenuto o di parti di esso viola i diritti di proprietà intellettuale.
  Questo copyright si applica ai servizi di IngrossoRicambi. Utilizzando questo sito web acconsenti ad accettare le disposizioni relative al copyright.
  `;

  const contentNormativeBatterie = `
  

    Ogni utilizzatore di pile, batterie, accumulatori deve contribuire alla protezione dell'ambiente, adottando comportamenti responsabili. L'attuale legge prevede che è vietato smaltire le batterie portatili esauste con i rifiuti domestici. Il consumatore ha l'obbligo di restituire le batterie usate ai rivenditori o di gettarle negli appositi contenitori di raccolta. Il simbolo del bidone barrato contrasegna le pile/batterie che contengono componenti pericolosi per l'ambiente (p.es Cd=cadmio; Pb=piombo; Hg=mercurio; Li=litio; Li-ion; ioni di litio; Ni=Nichel; Zi=zinco; Mh= ioduro metallico).

  `;

  const contentCondizioniGenerali = `Proprietà, Contenuto del Sito, Copyright e Marchi Registrati: Il sito e tutti i suoi contenuti, quali logo, icone, testi, ecc., sono di esclusiva proprietà di Doctor G. S.R.L.S. Essi sono protetti dalle leggi italiane e internazionali sul diritto d'autore. Il sito e tutti i suoi contenuti non possono essere, neanche parzialmente, riprodotti, distribuiti, venduti, trasferiti o modificati. I nomi, loghi e le immagini delle aziende e case produttrici presenti nel sito appartengono ai rispettivi proprietari. Il sito potrebbe contenere link a siti non gestiti dal sito stesso, i quali sono forniti come semplice riferimento e per il cui contenuto Doctor G. S.R.L.S., quale legittimo proprietario, non può essere ritenuto responsabile. Doctor G. S.R.L.S. non fornisce alcuna garanzia e declina qualsiasi responsabilità in relazione ai medesimi. Nel caso in cui l'utente decida di consultarli, lo fa a proprio rischio.

  Uso del sito: Il sito non può essere utilizzato in alcun modo per l'invio di materiale pornografico o che in ogni caso offenda il senso del comune pudore, o, più in generale, per finalità illecite. È vietata la riproduzione, la modifica, la duplicazione, la copia, la distribuzione, la vendita o comunque lo sfruttamento del sito per scopi commerciali. È ammessa la possibilità di scaricare o stampare una copia dei materiali contenuti nel sito per uso personale e scopi non speculativi. L'uso non autorizzato del sito e del materiale contenuto nel medesimo costituisce violazione delle leggi applicabili sulla proprietà intellettuale. L'utente si impegna a conservare tutte le indicazioni sui marchi e il diritto d'autore contenute sui materiali scaricati o copiati. L'uso di questi materiali su qualsiasi altro sito web è vietato.
  
  Età e responsabilità dell'utente: L'utente deve essere maggiorenne e dotato della capacità d'agire necessaria per utilizzare il presente sito, accettando le presenti Condizioni Generali e per assumersi ogni eventuale responsabilità derivante dall'uso del sito. L'utente che si registra è obbligato a indicare esclusivamente i propri dati personali e garantisce che essi siano veritieri, corretti e aggiornati. L'utente prende atto di essere responsabile per qualsiasi uso del sito da parte sua o di chi utilizzi i suoi dati personali.
  
  Esclusione di Garanzia: Il sito è fornito "così com'è." Doctor G. SRLS e i suoi fornitori non rilasciano alcuna garanzia circa l'operatività del sito, l'accuratezza, la completezza, la affidabilità delle informazioni, dei materiali e dei prodotti ivi contenuti che possono variare a seconda delle condizioni di mercato e management. Per quanto consentito dalle leggi applicabili, la Società non rilascia alcuna garanzia implicita o esplicita di commerciabilità e di idoneità ad uno scopo particolare.
  
  Limitazione di Responsabilità: Doctor G. SRLS non è responsabile per i danni di qualsiasi genere conseguenti all'utilizzo del sito, compresi i danni diretti, indiretti, incidentali e consequenziali.
  
  Legge Regolatrice: Alle presenti Condizioni Generali è applicabile la legge italiana.
  
  Modifiche alle Condizioni Generali: Doctor G. SRLS si riserva il diritto di modificare il sito e le presenti Condizioni Generali in qualsiasi momento. Delle modifiche verrà data comunicazione nel sito, e, fermo restando il diritto di recesso dell'utente, questi continuerà ad usare il sito secondo la versione modificata delle Condizioni Generali.
  
  Condizioni Ulteriori: Ulteriori termini e condizioni diversi dalle presenti potranno applicarsi alla vendita di beni e servizi o all'uso di altre parti del sito. L'utente dichiara sin da ora di conformarsi a tali altri termini e condizioni`;

  const contentPrivacyPolicy = `
  Dati utente raccolti automaticamente dal sistema: Lo Shop di vendita al dettaglio www.ingrossoricambi.net non utilizza log del server web. Ciò significa che non vengono esaminati automaticamente l'indirizzo IP, il tipo di browser, il volume dei dati, l'ora, il nome del file, del referente o del sistema operativo fino a quando non viene effettuato un ordine.

  Salvataggio dati sul sistema dell'utente (Cookies): Sono utilizzati cookies, ma sono temporanei e vengono cancellati automaticamente alla chiusura del browser. Nei cookie di sessione vengono memorizzati il carrello, i dati del cliente e le configurazioni. In altre aree del sito non vengono utilizzati cookies.
  
  Registrazione e salvataggio delle azioni dell'utente: I movimenti e i clic del visitatore nello Shop non vengono registrati né salvati fino a quando non si esegue un ordine.
  
  Registrazione e salvataggio dei dati personali: Durante la transazione, di default criptata SSL, la codifica sicura può essere disattivata dall'utente. Per poter spedire la merce al cliente, vengono salvati il nome, il nome aggiuntivo e l'e-mail. Come opzione, può essere inserito il numero di telefono. Questi dati vengono registrati solo alla conclusione del processo di ordinazione, quando viene visualizzato il messaggio "Il tuo ordine è stato trasmesso correttamente". In caso contrario, vengono cancellati dalla memoria e dal PC dell'utente alla chiusura del browser. Sarai diretto nello spazio di memorizzazione sotto indicato.
  
  Archiviazione / Accesso ai dati personali: I dati personali vengono salvati e archiviati solo ai fini e per il tempo necessario all'evasione dell'ordine. L'archivio dati è protetto da firewall e non è accessibile a terzi. Questi dati servono solo per la gestione della spedizione, la contabilità e, se necessario, per il diritto di recesso. Il proprietario di questa banca dati è Spares & Accessories GmbH, Eime, Germania, che funge da centro logistico e sviluppatore dello Shop. Così come il titolare dello Shop (vedi sigla editoriale), hanno accesso ai dati personali per l'emissione del pagamento, la spedizione, l'elaborazione dei reclami e la contabilità interna.
  
  Trattamento dei dati personali: Tutti i dati personali saranno trattati con la massima riservatezza e in conformità alla legge n.675 del 1996 e al D.lvo n.196 del 2003. Come utente, hai il diritto di verificare in qualsiasi momento i tuoi dati personali e di richiederne la cancellazione.
  
  Contatti: Doctor G. S.R.L.S. A socio unico
  C.f. - P.iva - Reg. imprese di Trento: 02382010227
  Rea: TN-220415 - Capitale sociale: € 900,00 i.v.
  
  `;

  const contentDirittoDiRecesso = `
  Il diritto di recesso è esercitabile entro 14 giorni dal ricevimento della merce, il consumatore può recedere dal contratto senza fornire alcuna spiegazione e senza il pagamento di penalità. La comunicazione di recesso dovrà essere effettuata tramite una dichiarazione esplicita (ad esempio lettera inviata per posta fax o posta elettronica). A tal fine il cliente puo’ utilizzare il modulo tipo di recesso, ma non e’ obbligatorio. Puo’ anche compilare e inviare elettronicamente il modulo tipo di recesso o qualsiasi altra esplicita dichiarazione sul nostro sito web. Nel caso scegliesse detta opzione, trasmetteremo senza indugio una conferma di ricevimento del recesso su un supporto durevole (ad esempio per posta elettronica). In seguito il Servizio Clienti provvederà rapidamente a comunicare al cliente le istruzioni sulla modalità di restituzione della merce che dovrà pervenire a Doctor G. SRLS entro 10 giorni dalla dichiarazione di recesso.

  Il diritto di recesso è escluso dalla legge nei seguenti casi:
  - prodotti (non a magazzino) prodotti/ordinati appositamente per il consumatore.
  - prodotti audio-video o software sigillati che siano stati aperti dal consumatore.
  
  La spese per la restituzione della merce sono a carico del consumatore che decide di esercitare il suo diritto di recesso. La merce restituita viaggia a carico e a rischio del consumatore, il venditore non assume responsabilità per danneggiamenti, furti o smarrimento di spedizioni non assicurate.
  
  Il venditore deve restituire il prezzo del prodotto pagato comprensivo delle spese di consegna al consumatore entro 14 giorni dal ricevimento della merce resa. Se il pagamento è avvenuto mediante bonifico bancario il consumatore è tenuto a comunicare al venditore i dati per potere effettuare il rimborso. La merce resa verrà esaminata per verificare eventuali danni o manomissioni non derivanti dal trasporto. Dopo aver controllato l’integrità dei beni, il venditore provvederà ad effettuare il rimborso che sarà effettuato utilizzando lo stesso mezzo di pagamento usato dal cliente per la transazione iniziale, salvo che non si sia espressamente convenuto altrimenti; in ogni caso,il cliente non dovrà sostenere alcun costo quale conseguenza di tale rimborso. La sola restituzione della merce non comporta un valido diritto di recesso dal contratto
  `;

  const contentCookies = `
  I cookie sono piccoli frammenti di testo che vengono memorizzati sul tuo dispositivo quando visiti il nostro sito di e-commerce. Questi componenti giocano un ruolo essenziale nell'ottimizzazione dell'esperienza del visitatore. Siamo impegnati a garantire trasparenza nell'uso dei cookie e a rispettare la tua privacy.

  Vogliamo che tu sappia che utilizziamo i cookie principalmente per migliorare l'esperienza di navigazione e per raccogliere dati anonimi sulle visite al nostro sito. Questi dati ci aiutano a capire meglio come i visitatori interagiscono con il nostro sito e a migliorarlo costantemente.

  È importante notare che utilizziamo diversi tipi di cookie:

      Alcuni cookie sono fondamentali per il funzionamento del sito e ci consentono di fornirti servizi di base, come il carrello della spesa e il processo di pagamento.

      Altri cookie sono finalizzati all'analisi del comportamento dei visitatori, inclusi il numero di visitatori, le pagine visitate e le fonti di traffico. Questo ci aiuta a prendere decisioni informate per migliorare il sito.

  In conclusione, ci impegniamo a utilizzare i cookie in modo responsabile e a rispettare la tua privacy. La tua esperienza sul nostro sito è importante, e siamo qui per rispondere a qualsiasi domanda o preoccupazione tu possa avere riguardo all'uso dei cookie.

  Grazie per aver scelto di fare acquisti su IngrossoRicambi.
  `;

  return (
    <footer className="mt-24 bottom-0 w-full h-full p-4 bg-white border-t border-gray-400 shadow-md md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full">
        <span className="text-sm text-black-500 dark:text-gray-400 mb-2 ml-4 md:mb-0">
          © 2024{" "}
          <Link href="http://ingrossoricambi.net/" className="hover:underline">
            IngrossoRicambi™ All Rights Reserved.
          </Link>
        </span>
        <ul className="grid grid-cols-2 gap-3 md:flex md:flex-row md:items-center md:mt-0 text-sm font-medium text-black-500 dark:text-gray-400">
          <li>
            <button
              onClick={openBatteryRegulationsModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Normative batterie
            </button>
          </li>
          <li>
            <button
              onClick={openReturnPolicyModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Diritto di recesso
            </button>
          </li>
          <li>
            <button
              onClick={openTermsAndConditionsModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Condizioni generali
            </button>
          </li>
          <li>
            <button
              onClick={openCookiesModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Cookies
            </button>
          </li>
          <li>
            <button
              onClick={openPrivacyPolicyModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Privacy
            </button>
          </li>
          <li>
            <button
              onClick={openCopyrightModal}
              className="mb-2 md:mb-0 mx-4 text-blue-700 hover:text-blue-500 hover:underline md:mr-6"
            >
              Copyright
            </button>
          </li>
          <Link to="/clientservice" className="ml-4">
            <li className="text-blue-600">Contattaci</li>
          </Link>
        </ul>
      </div>

      <Modal
        isOpen={isBatteryRegulationsModalOpen}
        onClose={closeModal}
        title="Normative sulle batterie"
        content={contentNormativeBatterie}
      />
      <Modal
        isOpen={isCookiesModalOpen}
        onClose={closeModal}
        title="Cookies"
        content={contentCookies}
      />
      <Modal
        isOpen={isReturnPolicyModalOpen}
        onClose={closeModal}
        title="Diritto di recesso"
        content={contentDirittoDiRecesso}
      />
      <Modal
        isOpen={isTermsAndConditionsModalOpen}
        onClose={closeModal}
        title="Condizioni generali"
        content={contentCondizioniGenerali}
      />
      <Modal
        isOpen={isPrivacyPolicyModalOpen}
        onClose={closeModal}
        title="Informativa sulla privacy"
        content={contentPrivacyPolicy}
      />
      <Modal
        isOpen={isCopyrightModalOpen}
        onClose={closeModal}
        title="Copyright"
        content={contentCopyright}
      />
    </footer>
  );
};

export default Footer;
