import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../WebParts/Navbar";
import Footer from "../WebParts/Footer";
import ProductDetails from "./Product/ProductDetails";
import AddToCart from "./Product/AddToCart";
import { FcInfo } from "react-icons/fc";
import InfoPoint from "../WebParts/InfoPoint";
import TopNavbar from "../WebParts/TopNavbar";
import CustomNavbar from "../WebParts/CustomNavbar"
import { useNavigate } from "react-router-dom";


export default function ProductPage({ setCart, cart, idSession }) {
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  // const [changePage, setChangePage] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    //console.log(idSession)
    handleNewProduct();
    // if(changePage){
    //   navigate('/results/das')
    // }
  }, [productId, idSession]);

  const handleNewProduct = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/product/${productId}/${idSession}`
      );
      setProduct(response.data);
      if(Object.keys(response.data).length === 2 && response.data["fehlernummer"] != 2){
        navigate(`/results/${productId}`)
      }
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  return (
    <div className="bg-gray-100">
      <TopNavbar />
            {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>        
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession}/>        
      </div>

      {(Object.keys(product).length !== 2 ? 
      <div className="container mx-auto py-8">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="md:flex-1 p-8">
            <img
              className="h-96 w-full object-contain"
              alt="Immagine prodotto"
              src={
                product.picurlbig
                  ? product.picurlbig
                  : require("../../../immagini/immagini_varie/no_picture.png")
              }
            />
          </div>
          <div className="md:flex-1 p-6 border-2">
            <h2 className="text-3xl font-semibold mb-4">{product.name}</h2>
            <p className="text-gray-700 mb-6">{product.description}</p>
            <ProductDetails product={product} />
            <div className="pt-8">
              <AddToCart product={product} setCart={setCart} idSession={idSession}/>
            </div>
          </div>
        </div>
      </div> :
      <>
        <div className="flex flex-col items-center justify-center p-16">
          <h1 className="text-xl md:text-4xl text-center rounded-lg">
            La ricerca non ha dato alcun risultato
          </h1>
          <p className="mt-4 text-xl text-center rounded-lg">
            Controlla se è corretto il codice modello, altrimenti{" "}
            <u>
              <Link to="/productrequest">clicca qui per richiedere un prodotto che non trovi</Link>
            </u>
          </p>
          <div className="flex items-center gap-2 mt-4">
            <FcInfo className="w-12 h-12 sm:w-8 sm:h-8" />
            <InfoPoint />
          </div>
        </div>
      </>
      )}

      

      {/* <div className="md:flex md:flex-col md:items-center border-4 border-black-300 md:mx-96 p-4 md:p-12 my-8 md:my-12">
        <h1 className="p-6 text-2xl md:text-4xl font-bold text-center rounded-lg">
          Stai cercando altri prodotti?
        </h1>

        <h1 className="p-6 text-2xl md:text-4xl font-bold text-center rounded-lg">
          Inserisci il codice modello o il numero di tipo
        </h1>

        <SearchBar idSession={idSession}/>
        <div className="flex pt-4 space-x-16">
          <div className="flex items-center gap-2">
            <FcInfo className="w-12 h-12 sm:w-8 sm:h-8" /> <InfoPoint />
          </div>
          <div className="flex items-center gap-2">
            <FcInfo className="w-12 h-12 sm:w-8 sm:h-8" /> <ComeFunziona />
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

