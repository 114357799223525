import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../WebParts/Navbar";
import Footer from "../WebParts/Footer";
import PaginationProductSearch from "../WebParts/PaginationProductsSearch";
import AddToCart from "../PaginaProdotto/Product/AddToCart";
import TopNavbar from "../WebParts/TopNavbar";
import VgruppeFilter from "../WebParts/VgruppeFilter";
import { Button } from "@material-tailwind/react";
import CustomNavbar from "../WebParts/CustomNavbar";
import IncrementPriceComponent from "../PaginaProdotto/Product/IncrementPriceComponent";

export default function ProductsSearch({
  cart,
  setCart,
  apparecchio,
  setApparecchio,
  marca,
  setMarca,
  productCode,
  setProductCode,
  codes,
  setCodes,
  idSession,
}) {
  const { productId, page } = useParams();
  const [articles, setArticles] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPages, setNumberPages] = useState(0);
  const [oldApparecchio, setOldApparecchio] = useState(false);
  const [oldMarchio, setOldMarchio] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [ricaricaArticles, setRicaricaArticles] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleSearchProducts();
  }, [productId, page, idSession]);

  useEffect(() => {
    setProductCode("");
    if (apparecchio) {
      setOldApparecchio(true);
      setOldMarchio(true);
    }
  }, []);

  useEffect(() => {}, [articles]);

  useEffect(() => {
    if (categorie.length !== 0 && ricaricaArticles) {
      // console.log("è stata appena selezionata una categoria")
      setArticles({});
      handleCategoriaClick();
      setRicaricaArticles(false);
    } else {
      if (categorie.length === 0) {
        setArticles({});
        setRicaricaArticles(true);
        handleSearchProducts();
      }
    }
  }, [categorie]);

  const handleSearchProducts = async () => {
    setArticles({});
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/searching/searchproducts/${productId}/${page}/${idSession}`
      );
      setNumberPages(Math.ceil(parseInt(response.data.anzahltreffer) / 10));
      // console.log(response.data.treffer)
      setArticles(response.data.treffer);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  async function setPrice(currentPrice) {
    if (typeof currentPrice !== "string") {
      throw new Error("Invalid price format");
    }

    let formattedPrice = currentPrice.replace(",", ".");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(
          formattedPrice
        ).toFixed(2)}`
      );
      return response.data.incrementedPrice.replace(".", ",");
    } catch (error) {
      console.error("Error fetching incremented price:", error);
      throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
    }
  }

  const handleCategoriaClick = async () => {
    setIsLoading(true); // Mostra la schermata di caricamento

    setArticles({});
    let temp_articles = {}; // Inizializza l'oggetto Articles come vuoto
    let indice = 0;

    for (let i = 0; i < numberPages; i++) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IP}/api/searching/searchproducts/${productId}/${i}/${idSession}`
        );
        // console.log("Ho fetchato la pagina n. " + i);
        const newArticles = response.data.treffer;
        // console.log(newArticles);

        // Unisci gli oggetti newArticles nell'oggetto articles in modo sequenziale
        Object.keys(newArticles).forEach((key, index) => {
          temp_articles[indice] = newArticles[key];
          indice += 1;
        });

        // console.log(temp_articles);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    }

    setArticles(temp_articles);
    setIsLoading(false); // Nascondi la schermata di caricamento quando il fetch è completato
  };

  function insertAppliance() {
    setOldApparecchio(true);
  }

  function insertMarchio() {
    setOldMarchio(true);
  }

  return (
    <>
      <TopNavbar />
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>
      
      <VgruppeFilter
        productId={productId}
        categorie={categorie}
        setCategorie={setCategorie}
        idSession={idSession}
      />

      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-gray-900">
          <div className="text-white p-4 bg-gray-800 rounded-md">
            Attendi un attimo...
          </div>
        </div>
      ) : null}

      {Object.keys(articles).length === 0 ? (
        <>
          <div className="container mx-auto px-4 py-8">
            <div className="flex items-center justify-center">
              <p className="text-xl italic text-center">
                Nessun prodotto associato all'apparecchio selezionato
              </p>
            </div>
          </div>

        </>
      ) : (
        <div className="container mx-auto px-4 py-8">
          <h2 className="text-2xl font-semibold mb-4">Risultati</h2>
          {categorie.length === 0 ? (
            <>
              <PaginationProductSearch
                numberPages={numberPages}
                productId={productId}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              <ul className="grid grid-cols-1 gap-4">
                {Object.keys(articles).map((key) => {
                  const article = articles[key];
                  const first5Digits = article.vgruppenid.substring(0, 5);

                  // Verifica se categorie è vuoto o se le prime 5 cifre di vgruppenid sono contenute in categorie
                  const shouldRender =
                    categorie.length === 0 || categorie.includes(first5Digits);

                  return shouldRender ? (
                    <li
                      key={article.artikelnummer}
                      className="bg-white p-4 rounded-md shadow-md mb-4 flex"
                    >
                      <Link
                        to={`/product/${article.artikelnummer}`}
                        className="block"
                      >
                        <div className="flex">
                          <div className="w-1/4 mr-4">
                            <img
                              src={
                                article.thumbnailurl
                                  ? article.thumbnailurl
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdT1RrLtX_ow1clmFGZmKvhd2hAwdT4mAkZu4cSXgN&s"
                              }
                              alt="immagine"
                              className="w-32 h-32 sm:w-150 sm:h-150 object-contain"
                            />
                          </div>
                          <div className="w-full">
                            <p className="text-xl">
                              {article.artikelbezeichnung}
                            </p>
                            <p>
                              <i>Categoria prodotto</i>: {article.vgruppenname}
                            </p>
                            <p>
                              <i>Numero aritcolo</i>: {article.artikelnummer}
                            </p>
                            <p>
                              <i>Tempo di spedizione</i>: {article.lieferzeit}
                            </p>
                            <p>
                              <i>Prezzo:</i>{" "}
                              <IncrementPriceComponent
                                currentPrice={article.ekpreis}
                              />{" "}
                              €
                            </p>
                            <p>
                              <i>Clicca per maggiori informazioni</i>
                            </p>
                            <div className="pt-4 w-full">
                              <AddToCart
                                product={article}
                                setCart={setCart}
                                idSession={idSession}
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ) : null;
                })}
              </ul>
              {categorie.length === 0 ? (
                <PaginationProductSearch
                  numberPages={numberPages}
                  productId={productId}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null}
            </>
          ) : (
            <ul className="grid grid-cols-1 gap-4">
              {Object.values(articles).map((article) => {
                const first5Digits = article.vgruppenid.substring(0, 5);

                // Verifica se categorie è vuoto o se le prime 5 cifre di vgruppenid sono contenute in categorie
                const shouldRender =
                  categorie.length === 0 || categorie.includes(first5Digits);

                return shouldRender ? (
                  <li
                    key={article.artikelnummer}
                    className="bg-white p-4 rounded-md shadow-md mb-4 flex"
                  >
                    <Link
                      to={`/product/${article.artikelnummer}`}
                      className="block"
                    >
                      <div className="flex">
                        <div className="w-1/4 mr-4">
                          <img
                            src={
                              article.thumbnailurl ||
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdT1RrLtX_ow1clmFGZmKvhd2hAwdT4mAkZu4cSXgN&s"
                            }
                            alt="immagine"
                            className="w-32 h-32 sm:w-150 sm:h-150 object-contain"
                          />
                        </div>
                        <div className="w-full">
                          <p className="text-xl">
                            {article.artikelbezeichnung}
                          </p>
                          <p>
                            <i>Categoria prodotto</i>: {article.vgruppenname}
                          </p>
                          <p>
                            <i>Numero articolo</i>: {article.artikelnummer}
                          </p>
                          <p>
                            <i>Prezzo:</i>{" "}
                            <IncrementPriceComponent
                              currentPrice={article.ekpreis}
                            />{" "}
                            €
                          </p>
                          <p>
                            <i>Clicca per maggiori informazioni</i>
                          </p>
                          <div className="pt-4 w-full">
                            <AddToCart
                              product={article}
                              setCart={setCart}
                              idSession={idSession}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
          )}
        </div>
      )}
      <Footer />
    </>
  );
}
