import { useEffect, useState } from "react";
import axios from "axios";

export default function ProductDetails({ product }) {
  const [prezzo, setPrezzo] = useState(0);

  useEffect(() => {
    if (typeof product.ekpreis !== "undefined") {
      setPrice(product.ekpreis);
    }
  }, [product]);

  async function setPrice(currentPrice) {
    let formattedPrice = currentPrice.replace(",", ".");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(
          formattedPrice
        ).toFixed(2)}`
      );

      setPrezzo(response.data.incrementedPrice.replace(".", ","));
    } catch (error) {
      console.error("Error fetching incremented price:", error);
      throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
    }
  }

  return (
    <>
      <section className="text-xl">
        <i>Nome del prodotto: </i> {product.artikelbezeichnung} <br />
        <i>Numero articolo: </i> <b>{product.artikelnummer}</b> <br />
        <i>Produttore Originale: </i> {product.artikelhersteller} <br />
        <i>Prezzo: </i>
        {prezzo} €<br />
        <i>Spedizione:</i>{" "}
        <span
          className={`inline-block ${
            product.lieferzeit && product.lieferzeit.startsWith("Disponibile")
              ? "text-green-500"
              : "text-orange-500"
          }`}
        >
          {product.lieferzeit || "N/D"}
        </span>
        <br />
        <b>
          {product.bestellbar === "N"
            ? "Non è disponibile"
            : "Prodotto disponibile"}
        </b>
      </section>
    </>
  );
}
