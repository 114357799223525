import { useEffect, useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

const PayPalCheckoutButton = (props) => {
  const { product, handleSubmit } = props;

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = (orderId) => {

    setPaidFor(true);

    // alert("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at info@ingrossoricambi.com for assistance.");
  };

  useEffect(() => {
    if (paidFor) {
      handleSubmit()
    }
  }, [paidFor]);

  if (error) {
    alert(error);
  }


  return (
    <>
      <PayPalButtons
        style={{
          color: "gold",
          layout: "horizontal",
          height: 48,
          tagline: false,
          shape: "pill",
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: {
                  value: product.price,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          // const order = await actions.order.capture();
          // // console.log("order", order);

          handleApprove(data.orderID);
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal Checkout onError", err);
        }}
        onCancel={() => {
            // Display cancel message, modal or redirect user to cancel page or back to cart
        }}
      />
    </>
  );
};

export default PayPalCheckoutButton;
