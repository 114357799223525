import React, { useState } from "react";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import axios from "axios";
import TornaAllaHome from "../WebParts/TornaAllaHome";
import CustomNavbar from "../WebParts/CustomNavbar";

export default function ProductRequest({ cart, idSession }) {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    ordernumber: "",
    postalcode: "",
    message: "",
  });

  const [isSending, setIsSending] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    type: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    // Se il campo message è vuoto, assegna "non definito"
    if (formData.message.trim() === "") {
      formData.message = "non definito";
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/orderrequest/${formData.nome}/${formData.cognome}/${formData.email}/${formData.ordernumber}/${formData.postalcode}/${formData.message}`
      );
      // console.log("Risposta dal server:", response.data);
      // console.log("Riuscito: ", response.data.success);

      if (response.data.success) {
        setNotification({
          message: "Email inviata con successo!",
          type: "success",
        });

        setFormData({
          nome: "",
          cognome: "",
          email: "",
          ordernumber: "",
          postalcode: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Errore nella richiesta HTTP:", error);

      setNotification({
        message: "Si è verificato un errore durante l'invio dell'email.",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>

      <div className="pb-16">
        <div className="container mx-auto px-4">
          <TornaAllaHome />
          <h1 className="text-3xl mb-4">Informazioni sull'ordine</h1>
          <p className="text-gray-800">
            Hai bisogno di avere informazioni sul tuo ordine? Compila il seguente modulo, inserendo il codice dell'ordine che le è stato inviato nella mail di conferma.
          </p>

          <form onSubmit={handleSubmit} className="max-w-md pt-8">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2" htmlFor="nome">
                Nome <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleInputChange}
                required
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="cognome"
              >
                Cognome <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="cognome"
                name="cognome"
                value={formData.cognome}
                onChange={handleInputChange}
                required
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2" htmlFor="email">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
              <small className="text-gray-600">
                Inserisci un indirizzo email valido.
              </small>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="ordernumber"
              >
                Numero d'ordine <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="ordernumber"
                name="ordernumber"
                value={formData.ordernumber}
                onChange={handleInputChange}
                required
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="postalcode"
              >
                Codice postale <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="postalcode"
                name="postalcode"
                value={formData.postalcode}
                onChange={handleInputChange}
                required
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2" htmlFor="message">
                Messaggio (opzionale)
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                rows="4"
                className="form-textarea w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <button
              type="submit"
              className="bg-indigo-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
              disabled={isSending}
            >
              {isSending ? "Invio in corso..." : "Invia Richiesta"}
            </button>
          </form>

          {notification.message && (
            <div
              className={`mt-4 p-4 text-white text-center ${
                notification.type === "success"
                  ? "bg-green-500"
                  : notification.type === "error"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              {notification.message}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
