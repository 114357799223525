import { Link } from "react-router-dom";

export default function TornaAllaHome() {
    return(
        <Link to="/">
            <button className="text-blue-600 pb-4">
                Torna alla home
            </button>
        </Link>
    )
}