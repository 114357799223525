import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CartView from "./CartComponent/CartView";
import TotalPrice from "./CartComponent/TotalPrice";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import TopNavbar from "../WebParts/TopNavbar";
import CustomNavbar from "../WebParts/CustomNavbar"

export default function CheckoutCart({ cart, setCart, setTotalPrice, totalPrice, shippingPrice, setShippingPrice, idSession }) {

  useEffect(() => {
  }, []);

  useEffect(() => {
    if(totalPrice>=150){
      setShippingPrice(0)
    }else{
      setShippingPrice(8.90)
    }
  }, [totalPrice]);
  

  return (
    <div className="min-h-screen">
      <TopNavbar />
            
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>        
      </div>


      {/* Visualizzazione su computer */}
      <div className="hidden md:block">
        <Navbar cart={cart} idSession={idSession}/>        
      </div>
      <div className="container mx-auto px-4 pb-8">
        <h2 className="text-2xl font-semibold mb-4 md:text-left text-center mt-2">Carrello</h2>
        <CartView cart={cart} setCart={setCart} setTotalPrice={setTotalPrice} idSession={idSession}/>

        {cart.positionen !== undefined && (
          <TotalPrice totalPrice={totalPrice} setTotalPrice={setTotalPrice} shippingPrice={shippingPrice}/>
        )}

        {cart.positionen !== undefined && (
          <Link to="/checkout/costumer_details">
            <button className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">
              Prosegui con l'ordine
            </button>
          </Link>
        )}
      </div>
      <Footer />
    </div>
  );
}
