import { AiFillStar } from 'react-icons/ai';
import { FcApproval } from "react-icons/fc";

import React from 'react';

export default function TopNavbar() {
    return (
        <div className="bg-blue-600 py-2 md:px-4 px-2">
            <div className="container mx-auto flex items-center justify-between">
                {/* Elementi a sinistra */}
                <div className="flex items-center space-x-4">
                    <p className="text-xs md:text-base text-white">Spedizione rapida garantita</p>
                </div>

                {/* Elementi al centro */}
                <div className="flex text-center">
                <p className="text-xs md:text-base text-white">Oltre 15.000.000 di ricambi disponibili</p>
                </div>

                {/* Elementi a destra */}
                <div className="flex items-center">
                    <FcApproval />
                    <p className="ml-1 text-xs md:text-base text-white">Qualità garantita </p>
                </div>
            </div>
        </div>
    );
}
