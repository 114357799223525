import React, { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FcIdea } from "react-icons/fc";
import { Link } from 'react-router-dom';
import Modal from '../../../WebParts/Modal';

export default function CustomInfoPoint() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalContent = (
    <div className="p-8">
      {/* Griglia di immagini */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {/* Immagine 1 */}
        <div className="flex flex-col items-center border p-4 rounded-md">
          <img
            src={require("../../../../../immagini/immagini_varie/codeModel1.jpg")}
            alt="Immagine 1"
            className="w-full sm:w-64 h-48 max-w-full mx-auto sm:w-1/2" // Riduci le dimensioni dell'immagine su dispositivi mobili
          />
          <p className="text-center text-sm">Cerca l'etichetta sul prodotto</p>
        </div>
  
        {/* Immagine 2 */}
        <div className="flex flex-col items-center border p-4 rounded-md">
          <img
            src={require("../../../../../immagini/immagini_varie/codeModel2.png")}
            alt="Immagine 2"
            className="w-full sm:w-64 h-64 max-w-full mx-auto sm:w-1/2" // Riduci le dimensioni dell'immagine su dispositivi mobili
          />
          <p className="text-center text-sm">Individua il codice modello</p>
        </div>
  
        {/* Immagine 3 */}
        <div className="flex flex-col items-center border p-4 rounded-md">
          <img
            src={require("../../../../../immagini/immagini_varie/codeModel3.jpg")}
            alt="Immagine 3"
            className="w-full sm:w-64 h-64 max-w-full mx-auto sm:w-1/2" // Riduci le dimensioni dell'immagine su dispositivi mobili
          />
          <p className="text-center text-sm">Solitamente in alto a sinistra</p>
        </div>
      </div>
      <div className="flex mt-4">
        <div className="ml-4">
          <p className="text-gray-700 text-xl">
            Non riesci a trovare il tuo dispositivo?{' '}
            <Link to='/productrequest' className='text-cyan-500'>Inviaci una foto della targhetta con il codice modello</Link> e una descrizione del prodotto che stavi cercando, e ti invieremo un link al prodotto corretto.
          </p>
        </div>
      </div>
    </div>
  );
  

  return (
    <div>
      <button
        className="text-blue-500 font-montserrat text-lg hover:underline cursor-pointer"
        onClick={openModal}
      >
        Codice modello?
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Dove trovo il Codice Modello?"
        content={modalContent}
      />

      {/* Modal */}

    </div>
  );
}
