import axios from "axios";
import { useEffect, useState } from "react";

export default function VgruppeFilter({ productId, categorie, setCategorie, idSession }) {
    const [vgruppe, setVgruppe] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    
    useEffect(() => {
        handleFilterSetup();
    }, [productId]);

    const handleFilterSetup = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IP}/api/articles/vgruppe/${productId}/${idSession}`);
            setVgruppe(response.data.treffer);
        } catch (error) {
            console.error('Errore nel settare i filtri', error);
        }
    };

    const handleCheckboxChange = (vgruppenid) => {
        const first5Digits = vgruppenid.substring(0, 5);

        // Copia l'array delle categorie selezionate
        const updatedCategories = [...selectedCategories];

        // Verifica se il vgruppenid è già presente nell'array
        if (updatedCategories.includes(first5Digits)) {
            // Rimuovi il vgruppenid se è già presente
            const index = updatedCategories.indexOf(first5Digits);
            updatedCategories.splice(index, 1);
        } else {
            // Aggiungi il vgruppenid se non è presente
            updatedCategories.push(first5Digits);
        }

        // Aggiorna lo stato delle categorie selezionate
        setSelectedCategories(updatedCategories);

        // Passa l'array delle categorie selezionate al componente padre
        setCategorie(updatedCategories);
    };

    // Funzione per gestire il click per aprire/nascondere il filtro su smartphone
    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <div className="bg-white p-4 rounded shadow-md">
            <p className="text-lg mb-2">Scegli la categoria che desideri per il tuo apparecchio</p>
            {/* Utilizza classi responsive per nascondere/mostrare il filtro su smartphone */}
            <div className={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 ${showFilter ? 'block' : 'hidden'}`}>
                {Object.values(vgruppe).map((item) => (
                    <div key={item.vgruppenid}>
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="checkbox"
                                value={item.vgruppenid}
                                checked={selectedCategories.includes(item.vgruppenid.substring(0, 5))}
                                onChange={() => handleCheckboxChange(item.vgruppenid)}
                                className="form-checkbox h-4 w-4 text-indigo-600 rounded"
                            />

                            <span className="text-gray-800">{item.vgruppenname}</span>
                        </label>
                    </div>
                ))}
            </div>
            {/* Aggiungi un pulsante per aprire/nascondere il filtro su smartphone */}
            <button
                className="block mt-4 bg-blue-600 text-white py-2 px-4 rounded cursor-pointer"
                onClick={toggleFilter}
            >
                {showFilter ? 'Nascondi Filtri' : 'Mostra Filtri'}
            </button>
        </div>
    );
}
