import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import Modal from "../../../WebParts/Modal";

export default function CustomComeFunziona() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalContent = (<div className="px-8">
  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
    {/* Prima riga */}
    <div className="bg-gray-200 p-4 rounded-lg">
      <figure className="relative h-48 w-full">
        <img
          className="h-48 w-full rounded-xl object-cover object-center"
          src={require("../../../../../immagini/immagini_varie/comeFunziona1.avif")}
          alt="nature image"
        />
        <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border-2 border-black bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
          <div>
            <Typography color="black" className="text-lg">
              Dispositivo rotto? Non preoccuparti!
            </Typography>
          </div>
        </figcaption>
      </figure>
    </div>

    <div className="bg-gray-200 p-4 rounded-lg">
      <figure className="relative h-48 w-full">
        <img
          className="h-48 w-full rounded-xl object-cover object-center"
          src={require("../../../../../immagini/immagini_varie/comeFunziona2.jpg")}
          alt="nature image"
        />
        <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border-2 border-black bg-white/90 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
          <div>
            <Typography color="black" className="text-lg">
              Inserisci il codice modello del tuo apparecchio sul
              nostro sito web
            </Typography>
          </div>
        </figcaption>
      </figure>
    </div>

    <div className="bg-gray-200 p-4 rounded-lg">
      <figure className="relative h-48 w-full">
        <img
          className="h-48 w-full rounded-xl object-cover object-center"
          src={require("../../../../../immagini/immagini_varie/comeFunziona3.jpg")}
          alt="nature image"
        />
        <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border-2 border-black bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
          <div>
            <Typography color="black" className="text-lg">
              Trova e ordina facilmente il ricambio per il tuo
              dispositivo
            </Typography>
          </div>
        </figcaption>
      </figure>
    </div>

    <div className="bg-gray-200 p-4 rounded-lg">
      <figure className="relative h-48 w-full">
        <img
          className="h-48 w-full rounded-xl object-cover object-center"
          src={require("../../../../../immagini/immagini_varie/comeFunziona4.png")}
          alt="nature image"
        />
        <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border-2 border-black bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
          <div>
            <Typography color="black" className="text-lg">
              Sostituisci la parte e il tuo dispositivo sarà come
              nuovo!
            </Typography>
          </div>
        </figcaption>
      </figure>
    </div>
  </div>
</div>);

  return (
    <div>
      
      <button
        className="text-blue-500 font-montserrat text-lg hover:underline cursor-pointer"
        onClick={openModal}
      >
        Come funziona?
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Come funziona IngrossoRicambi?"
        content={modalContent}
      />

    </div>
  );
}
