import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";


export default function AddToCart({ product, setCart, idSession }) {
    
  const [amount, setAmount] = useState(1)

  async function setPrice(currentPrice) {
    if (typeof currentPrice !== "string") {
        throw new Error("Invalid price format");
    }

    let formattedPrice = currentPrice.replace(",", ".");
    try {
        const response = await axios.get(`${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(formattedPrice).toFixed(2)}`);
        return response.data.incrementedPrice.replace(".", ",");
    } catch (error) {
        console.error('Error fetching incremented price:', error);
        throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
    }
  }

  const handleAddToCart = async () => {
    const newEkpreis = await setPrice(product.ekpreis)
    try {
      const formattedEkpreis = newEkpreis.toString().replace(',', '.');
      await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/${product.artikelnummer}/${amount}/${formattedEkpreis}/${idSession}`
      );
      handleUpdateCart();
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };


  const handleUpdateCart = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/cart/return/${idSession}`
      );
      setCart(response.data)
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  }


  return (
    <Link>
      {product.bestellbar !== "N" && (
        <button
          className="text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          onClick={handleAddToCart}
        >
          Aggiungi al carrello
        </button>
      )}
    </ Link>
  );
}
