import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AdminPricePanel from './AdminComponent/AdminPricePanel';
import AdminReturnedItemsPanel from './AdminComponent/AdminReturnedItemsPanel';


export default function AdminHome({ adminIsAuthenticated, setAdminIsAuthenticated }) {
  const [showPricePanel, setShowPricePanel] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showReturnedItemsPanel, setShowReturnedItemsPanel] = useState(false);

  const handleShowReturnedItemsPanel = () => {
    setShowDashboard(false);
    setShowPricePanel(false);
    setShowReturnedItemsPanel(true);
  };

  const handleCloseReturnedItemsPanel = () => {
    setShowReturnedItemsPanel(false);
    setShowDashboard(true);
  };

  const handleShowDashboard = () => {
    setShowDashboard(true);
    setShowPricePanel(false);
  };

  const handleShowPricePanel = () => {
    setShowDashboard(false);
    setShowPricePanel(true);
  };

  const handleClosePricePanel = () => {
    setShowPricePanel(false);
    setShowDashboard(true);
  };

  return (
    <div className="flex">
      {adminIsAuthenticated ? (
        <div className="flex flex-col w-full lg:flex-row">
          {/* Sidebar */}
          <aside className="bg-gray-800 text-white min-h-screen w-64 flex-shrink-0">
            {/* ... codice della sidebar */}
            <ul className="p-4">
                <h2 className='pb-8'>DASHBOARD</h2>
              {/* Alcune voci di menu */}
              <li className="mb-4">
                {/* Quando si fa clic su "Calcolo del ricarico %" mostra il pannello dei prezzi */}
                <button
                  onClick={handleShowDashboard}
                  className="block hover:text-blue-500 focus:outline-none"
                >
                  Home
                </button>
              </li>
              <li className="mb-4">
                {/* Quando si fa clic su "Calcolo del ricarico %" mostra il pannello dei prezzi */}
                <button
                  onClick={handleShowPricePanel}
                  className="block hover:text-blue-500 focus:outline-none"
                >
                  Calcolo del ricarico %
                </button>
              </li>
              <li className="mb-4">
                <button
                  onClick={handleShowReturnedItemsPanel}
                  className="block hover:text-blue-500 focus:outline-none"
                >
                  Articoli Restituiti
                </button>
              </li>
              <li className="mb-4">
                <Link to="/" className="block hover:text-blue-500">
                  Torna ad IngrossoRicambi
                </Link>
              </li>
              {/* ... altre voci di menu */}
            </ul>
          </aside>

          {/* Main Content */}
          <main className="flex-1 p-6">
            {/* ... altro contenuto del pannello principale */}
            {showPricePanel && (
              <div className="bg-white shadow-md rounded p-6">
                <h2 className="text-2xl mb-4 text-center font-semibold">Pannello dei prezzi</h2>
                <AdminPricePanel />
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleClosePricePanel}
                >
                  Chiudi
                </button>
              </div>
            )}
            {showDashboard && (
              <div className="bg-white shadow-md rounded p-6">
                <h2 className="text-2xl mb-4 text-center font-semibold">Benvenuto nella dashboard</h2>
              </div>
            )}
            {showReturnedItemsPanel && (
              <div className="bg-white shadow-md rounded p-6">
                <AdminReturnedItemsPanel />
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleCloseReturnedItemsPanel}
                >
                  Chiudi
                </button>
              </div>
            )}
          </main>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <div className="text-center">
            <p className="text-2xl font-semibold mb-4">
              Effettua l'accesso come amministratore
            </p>
            <Link to="/adminlogin" className="text-blue-500 hover:underline">
              Torna alla pagina di login
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
