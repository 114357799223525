import React from 'react';

const ListaMarcaDispositivi = () => {
    const marchi_popolari = ["AEG", "Apple", "Ariston", "Balay", "Bauknecht", "Beko", "Bosch", "Candy", "Constructa", "Electrolux", "Gorenje", "Hoover", "Hotpoint", "Ignis", "Indesit", "LG", "Liebherr", "NEFF", "Samsung", "Sharp", "Siemens", "Smeg", "Whirlpool", "Zanussi"];

    return (
        <div className="container mx-auto px-4 py-8 text-white">
            <h2 className="text-xl md:text-2xl mb-1 relative">
                Marchi popolari presenti sul nostro sito
                <div className="absolute bottom--4 left-0 w-[100px] h-0.5 bg-red-400 border-t-2 border-b-2 border-red-500 ml-1"></div>
            </h2>

            <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
            {marchi_popolari.map((marca, index) => (
                <li key={index} 
                    className="bg-white p-2 md:p-4 rounded-md shadow-md hover:border-red-500"
                >
                    <img
                        src={require(`../../immagini/loghi_marche/${marca}_logo.png`)}
                        alt={`${marca} Logo`}
                        width={100}
                        height={100}
                        className="mt-2 w-full h-8 md:h-16 lg:h-16 object-contain"
                    />
                </li>
            ))}
            </ul>
        </div>
    );
};

export default ListaMarcaDispositivi;