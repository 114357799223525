import React, { useState } from 'react';
import { Typography } from "@material-tailwind/react";

const CloseButton = ({ onClick }) => (
  <button
    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer z-50"
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);

const ModalSection = ({ imageSrc, caption }) => (
  <div className="bg-gray-200 p-4 rounded-lg">
    <figure className="relative h-80 w-full">
      <img
        className="h-full w-full rounded-xl object-cover object-center"
        src={require(`../../../immagini/immagini_varie/${imageSrc}`)}
        alt="nature image"
      />
      <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border-4 border-black bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
        <div>
          <Typography color="black" className="text-xl">
            {caption}
          </Typography>
        </div>
      </figcaption>
    </figure>
  </div>
);

export default function ComeFunziona() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        className="text-blue-500 hover:underline cursor-pointer"
        onClick={openModal}
      >
        Come funziona IngrossoRicambi?
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={closeModal}></div>
          <div className="bg-white rounded-lg shadow-lg z-50 w-full sm:w-11/12 relative">
            <CloseButton onClick={closeModal} />
            <div className="p-8">
              <h1 className="text-2xl mb-4">Come funziona IngrossoRicambi?</h1>
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                <ModalSection imageSrc="comeFunziona1.avif" caption="Dispositivo rotto? Non preoccuparti!" />
                <ModalSection imageSrc="comeFunziona2.jpg" caption="Inserisci il codice modello del tuo apparecchio sul nostro sito web" />
                <ModalSection imageSrc="comeFunziona3.jpg" caption="Trova e ordina facilmente il ricambio per il tuo dispositivo" />
                <ModalSection imageSrc="comeFunziona4.png" caption="Sostituisci la parte e il tuo dispositivo sarà come nuovo!" />
              </div>
              <div className='mt-8 flex justify-end'>
                <button
                  className="text-blue-500 cursor-pointer bg-transparent border border-blue-500 rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white"
                  onClick={closeModal}
                >
                  Ho capito!
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
