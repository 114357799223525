import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select"; // o un altro componente di selezione a tendina che preferisci
import { Link } from "react-router-dom";

export default function TripleSearchBarCustom() {
  const [devices, setDevices] = useState([]);
  const [modelCodes, setModelCodes] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [codiceModello, setCodiceModello] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visibleBrands, setVisibleBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [visibleModelCodes, setVisibleModelCodes] = useState([]);
  const [allModelCodes, setAllModelCodes] = useState([]);
  const [visibleDevices, setVisibleDevices] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [inputValueDevice, setInputValueDevice] = useState("");
  const [inputValueModelCode, setInputValueModelCode] = useState("");

  const loadMoreBrands = () => {
    const additionalBrands = allBrands.slice(
      visibleBrands.length,
      visibleBrands.length + 10
    );
    setVisibleBrands((prevVisibleBrands) => [
      ...prevVisibleBrands,
      ...additionalBrands,
    ]);
  };

  const loadMoreDevices = () => {
    const additionalDevices = allDevices.slice(
      visibleDevices.length,
      visibleDevices.length + 10
    );
    setVisibleDevices((prevVisibleDevices) => [
      ...prevVisibleDevices,
      ...additionalDevices,
    ]);
  };

  const loadMoreModelCodes = () => {
    const additionalModelCodes = allModelCodes.slice(
      visibleModelCodes.length,
      visibleModelCodes.length + 10
    );
    setVisibleModelCodes((prevVisibleModelCodes) => [
      ...prevVisibleModelCodes,
      ...additionalModelCodes,
    ]);
  };

  const handleBrandInputChange = (inputValue) => {
    const filteredBrands = allBrands.filter((brand) =>
      brand.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setVisibleBrands(filteredBrands.slice(0, 50));
  };

  const handleDeviceInputChange = (inputValue) => {
    setInputValueDevice(inputValue);
    const filteredDevices = allDevices.filter((device) =>
      device.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setVisibleDevices(filteredDevices);
  };

  const handleModelCodeInputChange = (inputValue) => {
    setInputValueModelCode(inputValue);
    const filteredModelCodes = allModelCodes.filter((modelCode) =>
      modelCode.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setVisibleModelCodes(filteredModelCodes);
  };

  useEffect(() => {
    setLoading(true);
    setError("");
    axios
      .get(`${process.env.REACT_APP_IP}/api/brands`)
      .then((response) => {
        const brandsData = response.data.map((brand) => ({
          value: brand.id,
          label: brand.brand_name,
        }));
        setAllBrands(brandsData);
        setVisibleBrands(brandsData.slice(0, 50)); // Ad esempio, mostra solo i primi 10 brand
      })
      .catch((error) => {
        console.error("Error fetching brands:", error);
        setError("Errore nel recuperare i brand.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      // console.log("Selected Brand: ", selectedBrand);
      setLoading(true);
      setError("");
      axios
        .get(
          `${process.env.REACT_APP_IP}/api/models/apparecchi/${selectedBrand.label}`
        )
        .then((response) => {
          const deviceData = response.data.map((device, index) => ({
            value: index,
            label: device.trim(),
          }));
          setAllDevices(deviceData);
          setVisibleDevices(deviceData.slice(0, 50)); // Mostra solo i primi 50 dispositivi
        })
        .catch((error) => {
          console.error("Error fetching devices:", error);
          setError("Errore nel recuperare i dispositivi.");
        });
    } else {
      // console.log("selectedBrand is not set");
      setDevices([]);
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedDevice) {
      setLoading(true);
      setError("");
      axios
        .get(
          `${process.env.REACT_APP_IP}/api/models/codiciModello/${selectedBrand.label}/${selectedDevice.label}`
        )
        .then((response) => {
          const modelCodeData = response.data.map((code) => ({
            value: code.id,
            label: code.model_name,
          }));
          setAllModelCodes(modelCodeData);
          setVisibleModelCodes(modelCodeData.slice(0, 50)); // Mostra solo i primi 50 codici modello
        })
        .catch((error) => {
          console.error("Error fetching model codes:", error);
          setError("Errore nel recuperare i codici modello.");
        });
    } else {
      setModelCodes([]);
    }
  }, [selectedDevice]);

  // Quando selectedBrand cambia, resetta selectedDevice e modelCodes
  useEffect(() => {
    if (!selectedBrand) {
      setSelectedDevice(null);
      setDevices([]);
      setCodiceModello(null);
      setModelCodes([]);
    }
  }, [selectedBrand]);

  // Quando selectedDevice cambia, resetta codiceModello e modelCodes
  useEffect(() => {
    if (!selectedDevice) {
      setCodiceModello(null);
      setModelCodes([]);
    }
  }, [selectedDevice]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '1rem', // Aumento della dimensione del testo
      border: state.isFocused ? "2px solid #2563eb" : "2px solid #d1d5db", // Miglioramento del bordo
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)", // Aggiunta di ombra per risalto
      borderRadius: "0.5rem", // Arrotondamento degli angoli
      padding: '8px', // Aggiunta di padding interno
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "0.5rem", // Arrotondamento degli angoli del menu
      zIndex: 100, // Assicurarsi che il menu a tendina sia sopra altri elementi
    }),
  };

  return (
    <>
      <div className="bg-gray-100 rounded-3xl p-4 mx-44 py-12 px-8">
        {" "}
        {/* Responsive padding */}
        <h1 className="text-left md:text-4xl font-semibold">
          Ricerca ricambio ottimizzata
        </h1>{" "}
        {/* Responsive text size */}
        <p className="text-left md:text-left md:mt-4 text-2xl">
          Trova facilmente il tuo ricambio, inserendo il marchio e il tipo di
          accessorio che stai cercando!
        </p>
        <div className="flex flex-col justify-between gap-4 md:mt-6">
          <Select
            options={visibleBrands}
            placeholder="Scegli il brand"
            onChange={setSelectedBrand}
            isClearable
            isSearchable
            className="w-full text-black"
            classNamePrefix="react-select"
            onInputChange={handleBrandInputChange}
            onMenuScrollToBottom={loadMoreBrands}
            isLoading={loading && visibleBrands.length === 0}
            styles={customStyles}
          />
          <Select
            options={visibleDevices}
            placeholder="Scegli il tipo di apparecchio"
            onChange={setSelectedDevice}
            isClearable
            isSearchable
            isDisabled={!selectedBrand}
            className={`w-full text-black ${
              !selectedBrand ? "opacity-50" : ""
            }`}
            classNamePrefix="react-select"
            onInputChange={handleDeviceInputChange}
            onMenuScrollToBottom={loadMoreDevices}
            isLoading={loading && visibleDevices.length === 0}
            styles={customStyles}
          />
          <Select
            options={visibleModelCodes}
            placeholder="Codice modello"
            onChange={(selectedOption) => setCodiceModello(selectedOption)}
            isClearable
            isSearchable
            isDisabled={!selectedDevice}
            className={`w-full text-black ${
              !selectedBrand || !selectedDevice ? "opacity-50" : ""
            }`}
            classNamePrefix="react-select"
            onInputChange={handleModelCodeInputChange}
            onMenuScrollToBottom={loadMoreModelCodes}
            isLoading={loading && visibleModelCodes.length === 0}
            styles={customStyles}
          />
        </div>
        <div className="flex justify-center mt-4 md:mt-6">
          <Link to={`/results/${codiceModello ? codiceModello.label : ""}`}>
            <button
              className="py-2 px-4 md:px-6 lg:px-8 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
              disabled={!selectedBrand || !selectedDevice || !codiceModello}
            >
              Mostra ricambi
            </button>
          </Link>
        </div>
        {error && <div className="text-red-500 text-center mt-2">{error}</div>}
      </div>
    </>
  );
}
