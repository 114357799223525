import React from "react";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import { Link } from "react-router-dom";
import TopNavbar from "../WebParts/TopNavbar";
import CustomNavbar from "../WebParts/CustomNavbar"
import ClientServiceComponent from "../Homepage/HomepageComponent/ClientService";

export default function CustomerServicePage({ cart, idSession }) {
  return (
    <div className="bg-gray-800">
      <TopNavbar />
      
      {/* Navbar Responsive */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>        
      </div>
      <div className="hidden md:block mb-4">
        <Navbar cart={cart} idSession={idSession}/>        
      </div>

      {/* Hero Section con Immagine di Sfondo e Overlay */}
      <header className="px-6 text-center bg-cover bg-center bg-customer-service-banner relative bg-gray-800">
        <div className="absolute bg-black opacity-50"></div>
        <div className="relative py-4">
          <h1 className="text-3xl md:text-5xl font-montserrat mb-4 text-blue-500">
            Servizio Clienti
          </h1>
        </div>
      </header>

      {/* Contenuto principale con layout a griglia e carte */}
      <main className="container mx-auto pb-8 px-6 md:py-4 bg-gray-800">
        <section className="mb-4 md:mb-12">
          <p className="text-white text-lg font-montserrat mb-6">
            Hai bisogno di assistenza? Non esitare a contattarci. Siamo
            disponibili per rispondere alle tue domande e aiutarti con i tuoi
            ordini.
          </p>

          {/* Form di contatto con animazione all'hover */}
          {/* Includi un modulo di contatto qui */}
        </section>

        <section className="mb-8 md:mb-12">
          <h2 className="text-xl md:text-3xl mb-6 text-white">Opzioni di contatto</h2>
          <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 text-white font-montserrat">
                <Link to={'/productrequest'}>
                    <li className="bg-blue-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                        <h3 className="text-2xl">Richiesta prodotto</h3>
                        <p className="mt-2">Non riesci a trovare il tuo prodotto? Compila il seguente modulo per inviare la tua richiesta per uno specifico prodotto</p>
                    </li>
                </Link>
                <Link to={'/orderrequest'}>
                    <li className="bg-green-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                        <h3 className="text-2xl">Informazioni sull'ordine</h3>
                        <p className="mt-2">Hai domande sull'ordine effettuato? Clicca qui.</p>
                    </li>
                </Link>
                <Link to={'/otherquestion'}>
                <li className="bg-purple-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                    <h3 className="text-2xl">Facci una domanda</h3>
                    <p className="mt-2">Hai una richiesta specifica da fare? Clicca qui per scriverci!</p>
                </li>
                </Link>
            </ul>
        </section>
      </main>

      <div className="py-24">

      </div>

      <Footer />
    </div>
  );
}

function ContactOptionCard({ to, title, description }) {
  return (
    <Link to={to} className="transform transition duration-500 hover:scale-105">
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg">
        <h3 className="text-blue-600 text-lg md:text-xl mb-3">{title}</h3>
        <p className="text-gray-600 text-base mb-4">{description}</p>
        <button className="text-white bg-blue-600 py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
          Clicca qui!
        </button>
      </div>
    </Link>
  );
}
