import React, { useEffect, useState } from "react";
import { MdDelete } from 'react-icons/md';

export default function QuantityEditor({ initialQuantity, onQuantityChange, onRemove }) {

    useEffect(() => {

    }, [])

  const [quantity, setQuantity] = useState(initialQuantity);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      onQuantityChange(quantity - 1);
    }
  };

  const handleInputChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
        onClick={handleDecrement}
      >
        -
      </button>
      <input
        type="number"
        className="w-16 text-center border border-gray-300 rounded"
        value={quantity}
        onChange={handleInputChange}
      />
      <button
        className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
        onClick={handleIncrement}
      >
        +
      </button>
      <button
        className="bg-gray-300 text-white px-2 py-1.5 rounded hover:bg-gray-600"
        onClick={onRemove}
      >
        <MdDelete style={{color:"black"}} size={20}/>
      </button>
    </div>
  );
}
