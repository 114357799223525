import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function PaginationProductSearch({ numberPages, productId, currentPage, setCurrentPage }) {
  const maxVisiblePages = 4; // Numero massimo di pagine visibili contemporaneamente
  
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage) {
      navigate(`/search/filters/${productId}/${currentPage}`);
    }
  }, [currentPage]);
  

  // Calcola un indice di inizio e fine per le pagine da visualizzare
  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(numberPages, startPage + maxVisiblePages - 1);

  if (numberPages <= maxVisiblePages) {
    // Se ci sono meno pagine di quelle da visualizzare, mostra tutte le pagine
    startPage = 1;
    endPage = numberPages;
  }

  const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handleUpdatePage(currentPage - 1)
    }
  };

  const handleClick = (pagina) => {
    setCurrentPage(pagina);
    handleUpdatePage(pagina)
  }

  const handleNextPage = () => {
    if (currentPage < numberPages) {
      setCurrentPage(currentPage + 1);
      handleUpdatePage(currentPage + 1)
    }
  };

  const handleUpdatePage = (pagina) => {
    scrollToTop()
    navigate(`/search/filters/${productId}/${pagina}`);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0, // Imposta la posizione in alto a cui desideri scorrere (in questo caso, la cima della pagina).
      behavior: 'smooth', // Aggiunge uno scorrimento animato.
    });
  }



  return (
    <nav>
      <ul className="flex justify-end">
        <li>
          <Link
            className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            aria-label="Previous"
            onClick={handlePreviousPage}
          >
            <span className="material-icons text-sm">{'<'}</span>
          </Link>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <Link
              className={`mx-1 flex h-9 w-9 items-center justify-center rounded-full ${
                page === currentPage ? 'bg-pink-500 text-white shadow-md' : 'border border-blue-gray-100 bg-transparent text-blue-gray-500 hover:bg-light-300'
              } p-0 text-sm transition duration-150 ease-in-out`}
              onClick={() => handleClick(page)}
            >
              {page}
            </Link>
          </li>
        ))}
        <li>
          <Link
            className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            aria-label="Next"
            onClick={handleNextPage}
          >
            <span className="material-icons text-sm">{'>'}</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
