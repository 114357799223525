import React, { useEffect, useState } from "react";
import Cart from "../Homepage/HomepageComponent/Navbar/Cart";
import { Link } from "react-router-dom";
import CustomSearchBar from "../Homepage/HomepageComponent/Navbar/CustomSearchBar";
import TopNavbarCustom from "./TopNavbarCustom";
import CustomInfoPoint from "../Homepage/HomepageComponent/Navbar/CustomInfoPoint";
import CustomComeFunziona from "../Homepage/HomepageComponent/Navbar/CustomComeFunziona";

export default function Navbar({ cart, idSession }) {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 200) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div>
      <nav
        className={`bg-gray-800 w-full z-10 pr-0 pl-0 fixed top-0 navbar-transition ${
          showNavbar ? "" : "navbar-hidden"
        }`}
      >
        <TopNavbarCustom />
        <div className="container mx-auto pl-8 pr-6 pt-2 pb-2 flex items-center justify-between">
          <div className="flex items-center justify-left w-full">
            <Link to="/">
              <img
                className="w-36"
                src={require("../../../immagini/logo/logo2.png")}
                width={1300}
                rel="preload"
                height={1}
                alt="Logo"
              />
            </Link>
          </div>

          {/* Barra di ricerca sempre visibile */}
          <div className="hidden md:block">
            <CustomSearchBar idSession={idSession} />
          </div>
          {/* Icona del carrello visibile solo su schermi mobili (md e inferiori) */}
          <div className="mt-3 mr-3">
            <Cart className="text-white" cart={cart} idSession={idSession} />
          </div>
        </div>
        <div className="md:flex md:flex-col md:items-center pt-2 pb-1 appear-animation-left rounded-lg shadow-lg">
          <CustomSearchBar idSession={idSession} />
        </div>
        <div className="bg-gray-800 md:hidden px-4 pb-1">
          <div className="grid grid-cols-2 pt-2">
            <div className="flex justify-center">
              <CustomInfoPoint />
            </div>
            <div className="flex justify-center">
              <CustomComeFunziona />
            </div>
          </div>
        </div>
      </nav>
      <div className="pb-48 bg-gray-800"></div>
    </div>
  );
}
