import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AdminPricePanel() {
  const [priceRanges, setPriceRanges] = useState([]);


  useEffect(() => {
    const fetchPriceRanges = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IP}/api/prices/ranges`);
        if (Array.isArray(response.data)) {
          setPriceRanges(response.data);
        } else {
          // Gestisci il caso in cui la risposta non sia un array
          console.error('La risposta non è un array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching price ranges:', error);
      }
    };
  
    fetchPriceRanges();
  }, []);
  

  const handlePercentageChange = (index, field, value) => {
    // Aggiorna il campo specifico (minPrice, maxPrice, o percentage)
    const updatedPriceRanges = [...priceRanges];
    updatedPriceRanges[index][field] = value;
    setPriceRanges(updatedPriceRanges);
  };

  const handleSave = async (index) => {
    const range = priceRanges[index];
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_IP}/api/prices/update-range`, range);
      alert(response.data.message); // Mostra un messaggio di conferma
    } catch (error) {
      console.error('Error updating price range:', error);
      alert('Error updating price range.'); // Mostra un messaggio di errore
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
        <h2 className="text-2xl font-semibold mb-4">Modifica delle Percentuali</h2>
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="py-2 px-4 border">Range</th>
              <th className="py-2 px-4 border">Percentuale</th>
              <th className="py-2 px-4 border">Azione</th>
            </tr>
          </thead>
          <tbody>
            {priceRanges.map((range, index) => ( // Usa 'priceRanges' invece di 'percentages'
              <tr key={index} className="odd:bg-gray-100">
                <td className="py-2 px-4 border">{`${range.minPrice} - ${range.maxPrice}`}</td>
                <td className="py-2 px-4 border">
                  <input
                    type="number"
                    value={range.percentage}
                    onChange={(e) => handlePercentageChange(index, 'percentage', parseFloat(e.target.value))}
                    className="w-20 px-2 py-1 border rounded-md"
                  />
                  %
                </td>
                <td className="py-2 px-4 border">
                  <button className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700" onClick={() => handleSave(index)}>
                    Salva
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
