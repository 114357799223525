import { useState, useEffect } from 'react';
import axios from 'axios';

export default function IncrementPriceComponent({ currentPrice }) {
  const [price, setPrice] = useState('');

  useEffect(() => {
    if (typeof currentPrice !== "string") {
      setPrice(currentPrice);
    }

    let formattedPrice = currentPrice.replace(",", ".");
    formattedPrice = parseFloat(formattedPrice).toFixed(2);

    axios.get(`${process.env.REACT_APP_IP}/api/prices/price/${formattedPrice}`)
      .then(response => {
        const new_price = response.data;
        setPrice(new_price.incrementedPrice.replace(".", ","));
      })
      .catch(error => {
        console.error('Error fetching incremented price:', error);
        setPrice("Error fetching price");
      });
  }, []);

  return price;
}
