import React, { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FcIdea } from "react-icons/fc";
import { Link } from 'react-router-dom';


export default function MyComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => { 
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        onClick={openModal}
      >
        Dove trovo il codice modello?
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={closeModal}></div>
          <div className="bg-white rounded-lg shadow-lg z-50 w-full max-w-6xl relative">
            <div className="sm:hidden">
              <div className="pt-4"></div>
              <div className="pt-96"></div>
              <div className="pt-96"></div>
            </div>
            <div className='mt-8 flex sm:hidden justify-end'>
              <button
                className="cursor-pointer bg-transparent border border-red-500 rounded-lg px-4 mr-2 mt-2 py-2 hover:bg-blue-500 hover:text-white"
                onClick={closeModal}
              >
                X
              </button>
            </div>
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer "
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Contenuto del modal */}
            <div className="p-8">
              <h1 className="text-2xl mb-4">Dove trovo il codice modello?</h1>
              <div className="flex pt-4">
                <div className="w-8 h-8 rounded-full flex items-center justify-center">
                  <FcIdea />
                </div>
                <div className="ml-4">
                  <p className="text-gray-700 text-xl">
                    Il numero corretto è il codice modello o numero di tipo e NON il numero di serie. Il codice modello + una serie di cifre e/o lettere. A volte il codice modello contiene un trattino (-) o una barra inclinata in avanti (/).
                  </p>
                </div>
              </div>
              {/* Griglia di immagini */}
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
                {/* Immagine 1 */}
                <div className="flex flex-col items-center border p-4 rounded-md">
                  <img
                    src={require("../../../immagini/immagini_varie/codeModel1.jpg")}
                    alt="Immagine 1"
                    className="w-full sm:w-64 h-64 max-w-full mx-auto sm:w-1/2"
                  />
                  <p className="text-center text-sm">Cerca l'etichetta sul prodotto</p>
                </div>

                {/* Immagine 2 */}
                <div className="flex flex-col items-center border p-4 rounded-md">
                  <img
                    src={require("../../../immagini/immagini_varie/codeModel2.png")}
                    alt="Immagine 2"
                    className="w-full sm:w-64 h-64 max-w-full mx-auto sm:w-1/2"
                  />
                  <p className="text-center text-sm">Individua il codice modello</p>
                </div>

                {/* Immagine 3 */}
                <div className="flex flex-col items-center border p-4 rounded-md">
                  <img
                    src={require("../../../immagini/immagini_varie/codeModel3.jpg")}
                    alt="Immagine 3"
                    className="w-full sm:w-64 h-64 max-w-full mx-auto sm:w-1/2"
                  />
                  <p className="text-center text-sm">Solitamente in alto a sinistra</p>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="w-8 h-8 rounded-full flex items-center justify-center">
                  <FaRegQuestionCircle />
                </div>
                <div className="ml-4">
                  <p className="text-gray-700 text-xl">
                    Non riesci a trovare il tuo dispositivo?{' '}
                    <Link to='/productrequest' className='text-cyan-500'>Inviaci una foto della targhetta con il codice modello</Link> e una descrizione del prodotto che stavi cercando, e ti invieremo un link al prodotto corretto.
                  </p>
                </div>
              </div>
              <div className='mt-8 flex justify-end text-blue-500'>
                <button
                  className="text-blue-500 cursor-pointer bg-transparent border border-blue-500 rounded-lg px-4 py-2 hover:bg-blue-500 hover:text-white"
                  onClick={closeModal}
                >
                  Ho capito!
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
