import React, { useState } from "react";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import axios from "axios";
import TornaAllaHome from "../WebParts/TornaAllaHome";
import TopNavbar from "../WebParts/TopNavbar";
import CustomNavbar from "../WebParts/CustomNavbar"

export default function ProductRequest({ cart, idSession }) {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    marchio: "",
    tipo: "",
    codice_modello: "",
    descrizione: "",
    allegato: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const clearNotification = () => {
    setNotification(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const dataForm = new FormData();
    dataForm.append("nome", formData.nome);
    dataForm.append("cognome", formData.cognome);
    dataForm.append("email", formData.email);
    dataForm.append("marchio", formData.marchio);
    dataForm.append("tipo", formData.tipo);
    dataForm.append("codice_modello", formData.codice_modello);
    dataForm.append("descrizione", formData.descrizione);
    dataForm.append("allegato", formData.allegato);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/product/productrequest`,
        dataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setIsLoading(false);

      if (response.data.success) {
        setNotification({
          type: "success",
          message: "Email inviata con successo!",
        });

        // Reimposta i campi del form a valori vuoti
        setFormData({
          nome: "",
          cognome: "",
          email: "",
          marchio: "",
          tipo: "",
          codice_modello: "",
          descrizione: "",
          allegato: null,
        });
      }
    } catch (error) {
      console.error("Errore nella richiesta HTTP:", error);
      setIsLoading(false);

      setNotification({
        type: "error",
        message: "Si è verificato un errore durante l'invio.",
      });
    }
  };

  return (
    <>
      <TopNavbar />
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>

      <div className="pb-16">
        <div className="container mx-auto px-4">
          <TornaAllaHome />
          <h1 className="text-3xl font-semibold mb-4">
            Richiesta di un Prodotto
          </h1>
          <p className="text-gray-800">
            Non riesci a trovare il tuo prodotto? Compila il seguente modulo per
            inviare la tua richiesta per uno specifico prodotto
          </p>

          <form onSubmit={handleSubmit} className="mt-8">
            {/* Sezione "Le tue Informazioni" */}
            <h2 className="text-xl mb-4">Le tue Informazioni</h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label htmlFor="nome" className="text-gray-600">
                  Nome <span className="text-red-500">*</span> :
                </label>
                <input
                  type="text"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="cognome" className="text-gray-600">
                  Cognome <span className="text-red-500">*</span> :
                </label>
                <input
                  type="text"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="cognome"
                  name="cognome"
                  value={formData.cognome}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="text-gray-600">
                  Indirizzo E-mail <span className="text-red-500">*</span> :
                </label>
                <input
                  type="email"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            {/* Sezione "Dettagli del Prodotto" */}
            <h2 className="text-xl mt-8 mb-4">Dettagli del Prodotto</h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label htmlFor="marchio" className="text-gray-600">
                  Marchio del Dispositivo (Opzionale):
                </label>
                <input
                  type="text"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="marchio"
                  name="marchio"
                  value={formData.marchio}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="tipo" className="text-gray-600">
                  Tipo di Dispositivo (Opzionale):
                </label>
                <input
                  type="text"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="tipo"
                  name="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="codice_modello" className="text-gray-600">
                  Codice Modello del Dispositivo (Opzionale):
                </label>
                <input
                  type="text"
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="codice_modello"
                  name="codice_modello"
                  value={formData.codice_modello}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="descrizione" className="text-gray-600">
                  Descrizione del Prodotto e/o Numero del Prodotto <span className="text-red-500">*</span> :
                </label>
                <textarea
                  className="w-full mt-2 p-2 border border-gray-400 rounded"
                  id="descrizione"
                  name="descrizione"
                  value={formData.descrizione}
                  onChange={handleInputChange}
                  rows="3"
                  required
                ></textarea>
              </div>
              <div>
                <label htmlFor="allegato" className="text-gray-600">
                  Allega un File Immagine (Opzionale):
                </label>
                <input
                  type="file"
                  className="w-full mt-2"
                  id="allegato"
                  name="allegato"
                  accept="image/*"
                  onChange={handleInputChange}
                />
              </div>

              {formData.allegato && (
                <div className="mt-2">
                  <label className="text-gray-600">Immagine Allegata:</label>
                  <div className="text-center">
                    <img
                      src={URL.createObjectURL(formData.allegato)}
                      alt="Allegato"
                      className="w-auto h-auto object-cover"
                    />
                    <button
                      type="button"
                      className="block mx-auto mt-2 p-3 border-2 text-red-600"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          allegato: null,
                        });
                      }}
                    >
                      Rimuovi
                    </button>
                  </div>
                </div>
              )}
            </div>
            <p className="text-red-600 mt-8">(* obbligatorio)</p>

            {/* Pulsante di Invio */}
            <button
              type="submit"
              className="mt-8 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? "Invio in corso..." : "Invia richiesta"}
            </button>
          </form>

          {notification && (
            <div className={`mt-4 p-3 ${notification.type === "success" ? "bg-green-200" : "bg-red-200"}`}>
              {notification.message}
              <button className="ml-2 text-sm" onClick={clearNotification}>
                Chiudi
              </button>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
