import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CartElement from "./CartElement";

export default function CartView({ setTotalPrice, setCart, cart, idSession }) {
    const [cartElements, setCartElements] = useState([]);
    const [triggerEffect, setTriggerEffect] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Aggiunto stato per il caricamento
    const location = useLocation();

    async function setPrice(currentPrice) {
        if (typeof currentPrice !== "string") {
            throw new Error("Invalid price format");
        }
    
        let formattedPrice = currentPrice.replace(",", ".");
        try {
            const response = await axios.get(`${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(formattedPrice).toFixed(2)}`);
            return response.data.incrementedPrice.replace(".", ",");
        } catch (error) {
            console.error('Error fetching incremented price:', error);
            throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
        }
    }

    useEffect(() => {
        fillCartView();
    }, [location, triggerEffect]);

    const fillCartView = async () => {
        setIsLoading(true); // Mostra la schermata di caricamento

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_IP}/api/cart/return/${idSession}`
            );

            if (!response.data.positionen) {
                setCart({});
                setIsLoading(false); // Nascondi la schermata di caricamento quando il fetch è completato
                return;
            }

            const cart = response.data;
            setCart(cart);

            const articleNumbers = Object.keys(cart.positionen).map(
                (key) => cart.positionen[key].artikelnummer
            );
            const amountNumbers = Object.keys(cart.positionen).map(
                (key) => cart.positionen[key].bestellmenge
            );
            let totalAmount = 0;
            const updatedCartElements = [];

            for (let i = 0; i < articleNumbers.length; i++) {
                const articolo = articleNumbers[i];
                const amount = amountNumbers[i];
        
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_IP}/api/product/${articolo}/${idSession}`
                    );
        
                    const updatedResponse = {
                        ...response.data,
                        bestellmenge: amount,
                    };
        
                    // Attendere che la promessa sia risolta e quindi convertire il risultato in float
                    const price = await setPrice(updatedResponse.ekpreis.replace(",", "."));
                    const formattedPrice = parseFloat(price.replace(",", "."));
        
                    totalAmount += formattedPrice * parseFloat(updatedResponse.bestellmenge);
        
                    updatedCartElements.push(updatedResponse);
                } catch (error) {
                    console.error(error);
                }
            }

            setTotalPrice(totalAmount.toFixed(2));
            setCartElements(updatedCartElements);
            setIsLoading(false); // Nascondi la schermata di caricamento quando il fetch è completato
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            {isLoading ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-gray-900">
                    <div className="text-white p-4 bg-gray-800 rounded-md">
                        Sto caricando il carrello...
                    </div>
                </div>
            ) : null}

            {cart.positionen !== undefined ? (
                <ul className="space-y-2">
                    {cartElements.map((element, index) => (
                        <li key={index} className="border-2 rounded-xl p-4 flex space-x-4 bg-gray-100">
                            <CartElement
                                element={element}
                                index={index}
                                setCart={setCart}
                                setTriggerEffect={setTriggerEffect}
                                triggerEffect={triggerEffect}
                                idSession = {idSession}
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-center">Il carrello è vuoto</p>
            )}
        </div>
    );
}
