import { useEffect } from "react";

export default function TotalPrice({
  totalPrice,
  setTotalPrice,
  shippingPrice,
}) {
  useEffect(() => {
  }, [totalPrice]);

  return (
    <div className="bg-gray-100 p-4 rounded-md shadow-md">
      <p className="text-xl font-semibold mb-2">
        Totale dell'ordine (compresi costi di spedizione):{" "}
        {parseFloat(parseFloat(totalPrice) + shippingPrice).toLocaleString(
          "it-IT",
          {
            style: "currency",
            currency: "EUR",
          }
        )}
      </p>
      <p className="text-sm">
        {shippingPrice === 0 ? (
          <span style={{ textDecoration: "underline" }}>
            Spedizione gratuita per ordini superiori a €150
          </span>
        ) : (
          `Spese di spedizione: ${parseFloat(shippingPrice).toLocaleString(
            "it-IT",
            {
              style: "currency",
              currency: "EUR",
            }
          )}`
        )}
      </p>
    </div>
  );
}
