import React, { useState, useEffect, useRef } from "react";
import FormDataSection from "./CheckoutComponent/FormData";
import PaymentMethodSection from "./CheckoutComponent/PaymentMethodSection";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import CartViewCheckout from "../Cart/CartComponent/CartViewCheckout";
import CustomNavbar from "../WebParts/CustomNavbar";
import TotalPrice from "../Cart/CartComponent/TotalPrice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CostumerDetails({
  cart,
  setCart,
  setTotalPrice,
  totalPrice,
  shippingPrice,
  numeroOrdine,
  setNumeroOrdine,
  idSession,
}) {
  const navigate = useNavigate();

  // Formdata con i dati dell'utente
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    telefono: "",
    via: "",
    numeroCivico: "",
    codicePostale: "",
    citta: "",
    codiceFiscale: "",
    isCodiceFiscaleValid: true,
  });

  // Metodo di pagamento
  const [paymentMethod, setPaymentMethod] = useState("Carta di credito");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  if (paymentMethod) { // Verifica che il metodo di pagamento sia stato selezionato
    setTimeout(() => {
      paymentMethodRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 300);
  }
  }, [paymentMethod]);

  // useEffect(() => {
  //   console.log(idSession)
  // }, [])

  // Aggiorna i dati del formData quando vengono modificati i dati personali
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Aggiungi un gestore di eventi per verificare il codice fiscale
  const handleCodiceFiscaleChange = (e) => {
    const codiceFiscale = e.target.value.toUpperCase(); // Converti in maiuscolo
    // const codiceFiscaleRegex = /^[A-Z0-9]{16}$/; // Regex per il codice fiscale italiano

    setFormData((prevData) => ({
      ...prevData,
      codiceFiscale: codiceFiscale,
      isCodiceFiscaleValid: isCodiceFiscaleValidFunction(codiceFiscale), // Verifica il formato del codice fiscale
    }));
  };

  // Funzione per verificare il codice fiscale italiano
  const isCodiceFiscaleValidFunction = (cf) => {
    if (cf.length !== 16) {
      return false; // Il codice fiscale italiano deve essere lungo 16 caratteri
    }

    // Verifica le prime tre lettere per il cognome
    if (!/^[A-Z]{3}/.test(cf.slice(0, 3))) {
      return false;
    }

    // Verifica le tre lettere successive per il nome
    if (!/^[A-Z]{3}/.test(cf.slice(3, 6))) {
      return false;
    }

    // Verifica i due caratteri numerici per l'anno di nascita
    if (!/^\d{2}/.test(cf.slice(6, 8))) {
      return false;
    }

    // Verifica il carattere alfabetico per il mese di nascita
    if (!/^[A-Z]/.test(cf[8])) {
      return false;
    }

    // Verifica i due caratteri numerici per il giorno di nascita e il sesso
    if (!/^\d{2}/.test(cf.slice(9, 11))) {
      return false;
    }

    // Verifica i quattro caratteri successivi per il Comune o lo Stato estero di nascita
    if (!/^[A-Z0-9]{4}/.test(cf.slice(11, 15))) {
      return false;
    }

    // Verifica il carattere di controllo
    if (!/^[A-Z]/.test(cf[15])) {
      return false;
    }

    return true;
  };

  const emailConfermaOrdine = async (returnId) => {
    // console.log("Mando la email di conferma all'utente");
    // console.log("Prezzo totale: " + prezzoTotale)

    // const artikelnummerArray = getArtikelnummerFromCart(cart);
    // console.log(artikelnummerArray)
    // const artikelnummerArrayJSON = JSON.stringify(artikelnummerArray);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/payments/confermaOrdine/${formData.email}/${totalPrice}/${shippingPrice}/${returnId}`
      );
    } catch (error) {
      console.error("Errore nella richiesta HTTP:", error);
    }
  };

  const emailConfermaOrdineIngrossi = async (returnId) => {
    // console.log("Mando la email di conferma a info@ingrossoricambi.com");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/payments/confermaOrdineIngrossi/${formData.email}/${totalPrice}/${shippingPrice}/${returnId}`
      );
      // console.log("Mail mandata!");
      // setIsEffectExecuted(true);
    } catch (error) {
      // console.error("Errore nella richiesta HTTP:", error);
    }
  };
 
  const handleSubmit = async () => {
    setIsLoading(true);
    // console.log("(Bonifico) Mando i dati: ");
    // console.log("PAYPAL OR BANK TRANSFER PAYMENT SEND ORDER");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/checkout/${(
          formData.nome +
          " " +
          formData.cognome
        ).replace(/\s+/g, "-")}/${formData.numeroCivico.replace(
          /\s+/g,
          "-"
        )}/${formData.via.replace(
          /\s+/g,
          "-"
        )}/${formData.codicePostale.replace(
          /\s+/g,
          "-"
        )}/${formData.citta.replace(/\s+/g, "-")}/${paymentMethod.replace(
          /\s+/g,
          "-"
        )}/${shippingPrice}/${formData.email.replace(
          /\s+/g,
          "-"
        )}/${formData.telefono.replace(
          /\s+/g,
          "-"
        )}/${formData.codiceFiscale.replace(/\s+/g, "-")}/${idSession}`
      );
      // console.log(response.data);
      // console.log(response.data.orderid);
      setNumeroOrdine(response.data.orderid);

      // confermaOrdine
      await emailConfermaOrdine(response.data.orderid);

      // confermaOrdineIngrosso
      await emailConfermaOrdineIngrossi(response.data.orderid);

      // Attendere che numeroOrdine sia stato impostato prima di navigare
      navigate(`/ordercompleted`);
    } catch (error) {
      console.error("Errore nel mandare i dati per l'ordine: ", error);
    } finally {
      setIsLoading(false); // Imposta la schermata di caricamento su false
    }
  };

  const paymentMethodRef = useRef(null);

  const handleSubmit2 = async () => {
    setIsLoading(true);
    //console.log("STRIPE PAYMENT SEND ORDER");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/checkout/${(
          formData.nome +
          " " +
          formData.cognome
        ).replace(/\s+/g, "-")}/${formData.numeroCivico.replace(
          /\s+/g,
          "-"
        )}/${formData.via.replace(
          /\s+/g,
          "-"
        )}/${formData.codicePostale.replace(
          /\s+/g,
          "-"
        )}/${formData.citta.replace(/\s+/g, "-")}/${paymentMethod.replace(
          /\s+/g,
          "-"
        )}/${shippingPrice}/${formData.email.replace(
          /\s+/g,
          "-"
        )}/${formData.telefono.replace(
          /\s+/g,
          "-"
        )}/${formData.codiceFiscale.replace(/\s+/g, "-")}/${idSession}`
      );

      // confermaOrdine
      await emailConfermaOrdine(response.data.orderid);

      // confermaOrdineIngrosso
      await emailConfermaOrdineIngrossi(response.data.orderid);

      return response.data.orderid; // Ritorna l'orderid
    } catch (error) {
      console.error("Errore nel mandare i dati per l'ordine: ", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Schermata da mostrare in caso di loading del processo di ordine */}
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
          <p className="text-white text-2xl">Sto processando l'ordine...</p>
        </div>
      )}

      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>
      </div>

      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession}/>        
      </div>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-semibold mb-4">Checkout</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <FormDataSection
              formData={formData}
              handleFormChange={handleFormChange}
              handleCodiceFiscaleChange={handleCodiceFiscaleChange}
            />

            {!formData.isCodiceFiscaleValid && (
              <p className="text-red-500 py-4">
                Il codice fiscale non è valido. Assicurati che sia composto da
                16 caratteri alfanumerici e sia corretto!
              </p>
            )}

            
            <div>
              <PaymentMethodSection
                paymentMethod={paymentMethod}
                totalPrice={totalPrice}
                shippingPrice={shippingPrice}
                setPaymentMethod={setPaymentMethod}
                handleSubmit={handleSubmit}
                email={formData.email}
                numeroOrdine={numeroOrdine}
                handleSubmit2={handleSubmit2}
              />
            </div>

            {(!formData.nome ||
              !formData.cognome ||
              !formData.email ||
              !formData.via ||
              !formData.numeroCivico ||
              !formData.codicePostale ||
              !formData.codiceFiscale ||
              !formData.citta ||
              !formData.isCodiceFiscaleValid) && (
                <div>
                  <p className="text-red-500">
                    Assicurati di aver fornito tutti i dati richiesti
                    correttamente
                  </p>
                </div>
            )}
            

            {(!formData.nome ||
              !formData.cognome ||
              !formData.email ||
              !formData.via ||
              !formData.numeroCivico ||
              !formData.codicePostale ||
              !formData.codiceFiscale ||
              !formData.citta ||
              !formData.isCodiceFiscaleValid) &&
              paymentMethod === "Bonifico" && (
                <div>
                  <p className="text-red-500">
                    Assicurati di aver fornito tutti i dati richiesti
                    correttamente
                  </p>
                  <button
                    disabled={true}
                    type="submit"
                    className="bg-red-500 text-white my-4 px-4 py-2 rounded hover:bg-red-600"
                  >
                    Effettua Pagamento
                  </button>
                </div>
            )}

            {formData.nome &&
              formData.cognome &&
              formData.email &&
              formData.via &&
              formData.numeroCivico &&
              formData.codicePostale &&
              formData.citta &&
              formData.codiceFiscale &&
              formData.isCodiceFiscaleValid &&
              paymentMethod === "Bonifico" && (
                <div>
                  <p className="text-green-500">Dati inseriti correttamente!</p>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white my-4 px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Completa l'ordine
                  </button>
                </div>
              )}
          </div>
          <div className="overflow-auto max-h-[calc(100vh-200px)]" ref={paymentMethodRef}>
            <div className="text-center rounded p-2">
              <p className="text-lg font-semibold text-gray-800">
                <TotalPrice
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  shippingPrice={shippingPrice}
                />
              </p>
            </div>
            <CartViewCheckout
              cart={cart}
              setCart={setCart}
              setTotalPrice={setTotalPrice}
              totalPrice={totalPrice}
              idSession={idSession}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
