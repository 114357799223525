import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../WebParts/Navbar";
import Footer from "../WebParts/Footer";
import { useParams } from "react-router-dom";
import CustomNavbar from "../WebParts/CustomNavbar";


export default function OrderCompleted({
  cart,
  setCart,
  shippingPrice,
  idSession,
  setIdSession,
}) {

  const [isEffectExecuted, setIsEffectExecuted] = useState(false);

  useEffect(() => {
    // console.log("numeroOrdine: " + returnId)
    // console.log("Prezzo totale all'inizio della funzione: " + prezzoTotale)
    async function fetchData() { 
      if (!isEffectExecuted) {
        // console.log("sono all'interno della funzione Stripe")
        // Il carrello è un oggetto vuoto, esegui le azioni desiderate
        // await emailConfermaOrdine();
        // await emailConfermaOrdineIngrossi();
        await handleClearCart();
        await handleNewSession();
      }
    }

    fetchData();

  }, []);

  const handleClearCart = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/removeItems/${idSession}`
      );
      // console.log("Cestino svuotato");
      setCart({})
    } catch (error) {
      console.error("Errore nella richiesta HTTPS:", error);
    }
  };

  const handleNewSession = async () => {
    try {
        const response = await axios.get(
        `${process.env.REACT_APP_IP}/userLogin`
        );
        console.log("Id impostato " + response.data)
        setIdSession(response.data);
        // Imposta uno stato di caricamento per un certo periodo di tempo prima di nasconderlo
        // setTimeout(() => setLoading(false), 10000); // 2000 millisecondi (2 secondi) di ritardo
        // setLoading(false);
    } catch (error) {
        console.error("Error on this Session:", error);
        // setLoading(false); // Assicurati che lo stato di caricamento sia comunque impostato su false in caso di errore
    }
  };

  // function getArtikelnummerFromCart(cart) {
  //     const artikelnummerArray = [];

  //     // Verifica se l'oggetto cart esiste e se ha la proprietà 'positionen'
  //     if (cart && cart.positionen) {
  //       const positionen = cart.positionen;

  //       // Itera attraverso le chiavi dell'oggetto positionen
  //       for (const key in positionen) {
  //         if (positionen.hasOwnProperty(key)) {
  //           const artikel = positionen[key];

  //           // Verifica se l'articolo ha la proprietà 'artikelnummer' e aggiungila all'array
  //           if (artikel && artikel.artikelnummer) {
  //             artikelnummerArray.push(artikel.artikelnummer);
  //           }
  //         }
  //       }
  //     }

  //     return artikelnummerArray;
  //   }

  // const emailConfermaOrdine = async () => {
  //   // console.log("Mando la email di conferma all'utente");
  //   // console.log("Prezzo totale: " + prezzoTotale)

  //   // const artikelnummerArray = getArtikelnummerFromCart(cart);
  //   // console.log(artikelnummerArray)
  //   // const artikelnummerArrayJSON = JSON.stringify(artikelnummerArray);

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_IP}/api/payments/confermaOrdine/${email}/${prezzoTotale}/${shippingPrice}/${returnId}`
  //     );
  //     // console.log(response.data);
  //   } catch (error) {
  //     console.error("Errore nella richiesta HTTP:", error);
  //   }
  // };


  // const emailConfermaOrdineIngrossi = async () => {
  //   // console.log("Mando la email di conferma a info@ingrossoricambi.com");
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_IP}/api/payments/confermaOrdineIngrossi/${email}/${prezzoTotale}/${shippingPrice}/${returnId}`
  //     );
  //     // console.log("Mail mandata!");
  //     setIsEffectExecuted(true);
  //   } catch (error) {
  //     // console.error("Errore nella richiesta HTTP:", error);
  //   }
  // };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession}/>        
      </div>
      <div className="flex-grow flex justify-center items-center">
        <div className="bg-white p-8 shadow-md rounded-md text-center">
          <h2 className="text-2xl font-semibold mb-4">
            Ordine effettuato con successo!
          </h2>
          <p className="text-lg mb-4">
            Grazie per il tuo ordine. Riceverai una conferma via email.
          </p>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out"
            onClick={() => (window.location.href = "/")}
          >
            Torna alla homepage
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
