import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const CookieConsent = ({ setCookieConsent }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);
  const [showBanner, setShowBanner] = useState(false);

  // Funzione per gestire il consenso
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
    setCookieConsent(true);
    setShowBanner(false);
  };

  // Funzione per gestire la mancata accettazione del consenso
  const noCookieConsent = () => {
    // Considera se vuoi realmente impostare il cookie qui o semplicemente nascondere il banner
    // removeCookie("cookieConsent", { path: "/" });
    setShowBanner(false);
  };

  // Controlla lo stato del consenso al caricamento del componente
  useEffect(() => {
    if (!cookies.cookieConsent) {
      setShowBanner(true);
    }
  }, [cookies.cookieConsent]);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 z-50">
      <div className="container mx-auto text-center">
        <div className="py-2">
          <h1 className="font-bold text-xl mb-2">Questo sito web utilizza i cookie</h1>
          <p className="text-lg mb-4">
            Siamo impegnati a garantire trasparenza nell'uso dei cookie e a rispettare la tua privacy. Utilizziamo
            cookie essenziali per il funzionamento del sito e cookie per l'analisi del comportamento dei visitatori.
            Ci impegniamo a utilizzare i cookie in modo responsabile per arricchire la tua esperienza online. Grazie
            per fare acquisti su IngrossoRicambi.
          </p>
          <Link to="/privacy-policy" className="text-blue-400 hover:underline">Leggi di più</Link>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <button
            onClick={noCookieConsent}
            className="bg-orange-700 hover:bg-orange-900 text-white px-4 py-2 rounded"
          >
            Continua senza accettarli
          </button>
          <button
            onClick={giveCookieConsent}
            className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Accetta
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
