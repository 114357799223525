import Footer from "../WebParts/Footer";
import CustomNavbar from "../WebParts/CustomNavbar";
import ListaMarcaDispositivi from "../../Homepage/ListaMarcaDispositivi";
import { useState, useEffect } from "react";
import Features from "./HomepageComponent/Features";
import CookieConsent from "../../Homepage/CookieConsent";
import { useCookies } from "react-cookie";
import LandingPage from "./HomepageComponent/LandingPage";
import ClientServiceComponent from "./HomepageComponent/ClientService";
import CustomLandingPage from "./HomepageComponent/CustomLandingPage";
import TripleSearchBar from "./HomepageComponent/TripleSearchBar";


export default function Homepage({
  cart,
  setProductCode,
  idSession,
}) {
  const [cookies] = useCookies(["cookieConsent"]);
  const [cookieConsent, setCookieConsent] = useState(false);


  useEffect(() => {
    setProductCode("");
  }, []);


  return (
    <>
      {!cookies.cookieConsent && (
        <CookieConsent setCookieConsent={setCookieConsent} />
      )}

      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession}/>        
      </div>

      {/* Visualizazzione su computer */}
      <div className="hidden md:block bg-gray-800">
        <LandingPage idSession={idSession} cart={cart}/>
        <ClientServiceComponent />
        <Features />
        <ListaMarcaDispositivi />
        <Footer />
      </div>
      
      {/* Visualizzazione su smartphone */}
      <div className="block md:hidden bg-gray-800">
        <CustomLandingPage idSession={idSession} cart={cart}/>
        <TripleSearchBar/> 
        <ClientServiceComponent />
        <ListaMarcaDispositivi />
        <Features />
        <Footer />
      </div>
    </>
  );
}
