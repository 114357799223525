import React, { useState, useEffect } from "react";
import axios from 'axios';

const CommonFunction = ({ setIdSession }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    handleNewSession();
  }, []);

  const handleNewSession = async () => {
    try {
        const response = await axios.get(
        `${process.env.REACT_APP_IP}/userLogin`
        );
        console.log("Id impostato " + response.data)
        setIdSession(response.data);
        // Imposta uno stato di caricamento per un certo periodo di tempo prima di nasconderlo
        // setTimeout(() => setLoading(false), 10000); // 2000 millisecondi (2 secondi) di ritardo
        // setLoading(false);
    } catch (error) {
        console.error("Error on this Session:", error);
        // setLoading(false); // Assicurati che lo stato di caricamento sia comunque impostato su false in caso di errore
    }
  };

  return (
    <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-70 z-50 ${loading ? '' : 'hidden'}`}>
      <div className="bg-white p-8 rounded shadow-lg">
        <div className="animate-pulse">
          <div className="w-16 h-16 bg-gray-300 rounded-full mb-4"></div>
          <div className="w-32 h-6 bg-gray-300 rounded mb-4"></div>
        </div>
        <p className="text-xl text-gray-700">Caricamento...</p>
      </div>
    </div>
  );
};

export default CommonFunction;
