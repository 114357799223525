import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from 'axios';

import StripeCheckoutForm from "./StripeCheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_live_51LZhSqG1bnjT3mx3nG8B1Djo7P7q7CPkdFOKtNVTtVEwvEWIM3aTiDn55M0GijDE57XVHjDepU0Ck1dJTdWtplR200TjQpN5mw");
// pk_test_51LZhSqG1bnjT3mx317UAJyLH7s3Od4s3v2By2mAj8yNwF8ulJbXkTG8eMkl8YT0WHrmM2DUPIQzhsIvA73d8ZeR100ZAlju2zz
// pk_live_51LZhSqG1bnjT3mx3nG8B1Djo7P7q7CPkdFOKtNVTtVEwvEWIM3aTiDn55M0GijDE57XVHjDepU0Ck1dJTdWtplR200TjQpN5mw

export default function App({ totalPrice, handleSubmit2, email, numeroOrdine, prezzoTotale }) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Crea un PaymentIntent non appena la pagina viene caricata
    axios.post(`${process.env.REACT_APP_IP}/api/payments/stripe/create-payment-intent/${totalPrice*100}`)
    .then((response) => {
        const data = response.data;
        // console.log(data)
        setClientSecret(data.clientSecret);
    })
    .catch((error) => {
        console.error("Errore durante la richiesta PaymentIntent:", error);
    });
  }, []);
  

  //  Cazzodipassword2 

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
 
  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripeCheckoutForm handleSubmit2={handleSubmit2} email={email} numeroOrdine={numeroOrdine} prezzoTotale={prezzoTotale}/>
        </Elements>
      )}
    </div>
  );
}