import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import TopNavbar from "../WebParts/TopNavbar";
import Navbar from "../WebParts/Navbar";
import CustomNavbar from "../WebParts/CustomNavbar";
import { FcInfo } from "react-icons/fc";
import InfoPoint from "../WebParts/InfoPoint";
import LoadingSpinner from "../WebParts/LoadingSpinner";
import TripleSearchBar from "../Homepage/HomepageComponent/TripleSearchBar";
import Footer from "../WebParts/Footer";

export default function RisultatiRicerca({ idSession, cart }) {
  const navigate = useNavigate();
  const { keywords } = useParams();

  const [applianceArray, setApplianceArray] = useState([]);
  const [articoliArray, setArticoliArray] = useState([]);
  const [categorieArray, setCategorieArray] = useState([]);
  const [css, setCss] = useState("");

  const [apparecchiTrovati, setApparecchiTrovati] = useState([]);
  const [articoliTrovati, setArticoliTrovati] = useState([]);
  const [categorieTrovati, setCategorieTrovati] = useState([]);

  const [clickedItemType, setClickedItemType] = useState(null); // Check!
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchResultForKeywords();
  }, [idSession]);

  useEffect(() => {
    searchResultForKeywords();
  }, [keywords]);

  const searchResultForKeywords = async () => {
    setLoading(true);
    let risultato = false;
    const cleanedKeywords = keywords
      .replace(/\([^)]*\)/g, "")
      .replace(/\/+/g, " ")
      .replace(/\s\s+/g, " ")
      .trim();

    // Mi puoi togliere le parentesi con ciò che al loro interno

    const keywordsArray = cleanedKeywords.split(" ");
    const searchCombinations = getSearchCombinations(keywordsArray);

    for (let combination of searchCombinations) {
      // console.log("Combinazione: " + combination)
      risultato = await trySearch(combination);
      // console.log("Risultato: " + risultato + " - " + combination)
      if (risultato) {
        // console.log("Trovato!")
        setHasResults(true);
        break;
      }
    }

    if (!risultato) {
      setHasResults(false);
      // Gestisci cosa fare se non ci sono risultati
    }

    setSearchCompleted(true);
    setLoading(false);
  };

  const getSearchCombinations = (keywordsArray) => {
    const combinations = [];
    const n = keywordsArray.length;

    // Combinazione con tutte le parole
    combinations.push(keywordsArray.join(" "));

    // Combinazioni con tutte le parole tranne una
    for (let i = 0; i < n; i++) {
      let tempArray = [...keywordsArray];
      tempArray.splice(i, 1); // Rimuovi l'elemento in posizione i
      combinations.push(tempArray.join(" "));
    }

    // Combinazioni di due parole
    if (n > 2) {
      for (let i = 0; i < n; i++) {
        for (let j = i + 1; j < n; j++) {
          combinations.push(keywordsArray[i] + " " + keywordsArray[j]);
        }
      }
    }

    // Aggiungi ogni singola parola
    combinations.push(...keywordsArray);

    return combinations.filter(
      (elem, index, self) => self.indexOf(elem) === index
    );
  };

  const trySearch = async (searchString) => {
    try {
      // console.log(searchString);
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/articles/${searchString}/${idSession}`
      );
      setCss(convertToJSX(response.data.suggests.css));
      setArticoliTrovati(response.data.suggests.artikeltreffer);
      setApparecchiTrovati(response.data.suggests.geraetetreffer);
      setCategorieTrovati(response.data.suggests.vgruppentreffer);

      setApplianceArray([]);
      setArticoliArray([]);
      setCategorieArray([]);

      // Riempi l'array degli apparecchi se sono stati trovati
      if (response.data.suggests.geraetetreffer) {
        const geraetebezeichnungArray =
          response.data.suggests.geraetetreffer.map(
            (item) => item.geraetebezeichnung
          );
        setApplianceArray(geraetebezeichnungArray);
      }

      // Riempi l'array degli articoli se sono stati trovati
      if (response.data.suggests.artikeltreffer) {
        const artikelbezeichnungArray =
          response.data.suggests.artikeltreffer.map(
            (item) => item.artikelbezeichnung
          );
        setArticoliArray(artikelbezeichnungArray);
      }

      // Riempi l'array delle categorie se sono stati trovate
      if (response.data.suggests.vgruppentreffer) {
        const vgruppennameArray = response.data.suggests.vgruppentreffer.map(
          (item) => item.vgruppenname
        );
        setCategorieArray(vgruppennameArray);
      }

      return (
        response.data.suggests.geraetetreffer.length > 0 ||
        response.data.suggests.artikeltreffer.length > 0 ||
        response.data.suggests.vgruppentreffer.length > 0
      );
    } catch (error) {
      console.error("Error fetching articles:", error);
      return false; // Restituisce false se c'è stato un errore o non sono stati trovati risultati
    }
  };

  // Funzione che converte in JSX
  function convertToJSX(cssString) {
    const cleanCss = cssString
      .replace('<style type="text/css">', "")
      .replace("</style>", "");

    return (
      <style type="text/css">
        {`
                  ${cleanCss}
                `}
      </style>
    );
  }

  const combinedResults = [
    { label: "Apparecchi", items: applianceArray },
    { label: "Articoli", items: articoliArray },
    { label: "Categorie", items: categorieArray },
  ];

  const handleItemClick = (itemName, itemType) => {
    switch (itemType) {
      case "Articoli":
        const foundArticolo = articoliTrovati.find(
          (articolo) => articolo.artikelbezeichnung === itemName
        );
        if (foundArticolo) {
          navigate(`/product/${foundArticolo.artikelnummer}`);
          // console.log(
          //   `Artikelnummer associato: ${foundArticolo.artikelnummer}`
          // );
        } else {
          // console.log("Articolo non trovato");
        }
        break;

      case "Apparecchi":
        const foundApparecchio = apparecchiTrovati.find(
          (apparecchio) => apparecchio.geraetebezeichnung === itemName
        );
        if (foundApparecchio) {
          navigate(`/search/filters/${foundApparecchio.geraeteid}/1`);
          // console.log(`Geraeteid associato: ${foundApparecchio.geraeteid}`);
        } else {
          // console.log("Apparecchio non trovato");
        }
        break;

      case "Categorie":
        const foundCategoria = categorieTrovati.find(
          (categoria) => categoria.vgruppenname === itemName
        );
        if (foundCategoria) {
          navigate(
            `/product/filters/${keywords}/${foundCategoria.vgruppenid}/1`
          );
          // console.log(`Vgruppenid associato: ${foundCategoria.vgruppenid}`);
          // console.log(`Testo della barra di ricerca: ${inputValue}`);
        } else {
          // console.log("Categoria non trovata");
        }
        break;

      default:
      // console.log("Tipo di elemento non gestito");
    }

    setClickedItemType(itemType);
  };

  return (
    <>
      <TopNavbar />
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>

      {loading ? (
        <LoadingSpinner /> // Mostra lo spinner di caricamento
      ) : (
        <div className="w-full">
          {combinedResults.length > 0 && (
            <>
              <div className="bg-white rounded-lg shadow-lg overflow-hidden md:mx-auto max-w-4xl my-8 mx-4">
                {combinedResults.map((resultGroup, groupIndex) => (
                  <div key={groupIndex} className="border-b last:border-b-0">
                    {resultGroup.items.length > 0 && (
                      <>
                        <p className="px-4 py-2 font-semibold text-blue-600 bg-blue-50 text-lg md:text-xl lg:text-2xl">
                          {resultGroup.label} trovati per{" "}
                          <span className="text-gray-700">{keywords}</span>
                        </p>
                        {resultGroup.items.map((result, index) => (
                          <p
                            key={index}
                            className="px-4 py-2 border-b last:border-b-0 hover:bg-blue-100 cursor-pointer text-sm md:text-base lg:text-lg transition-colors duration-150 ease-in-out"
                            onClick={() => {
                              handleItemClick(result, resultGroup.label);
                              setApplianceArray([]);
                              setArticoliArray([]);
                              setCategorieArray([]);
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: result }}
                              className="mb-1"
                            />
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="md:px-24 px-4">
                <TripleSearchBar />
              </div>
            </>
          )}

          <Footer />

          {css}
        </div>
      )}
    </>
  );
}
