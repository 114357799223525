import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import TotalPrice from "../../Cart/CartComponent/TotalPrice";

export default function CheckoutForm({ handleSubmit2, email, numeroOrdine, prezzoTotale }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    // if (!clientSecret) {
    //   return;
    // }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       setMessage("Something went wrong.");
    //       break;
    //   }
    // });

    // console.log(message)

  }, [stripe]);

  const handleSubmition = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const returnId = await handleSubmit2();

    //console.log("Qui chiamo le altre due funzioni" + returnId)

    
    // email conferma ordine
    // email conferma ordine ingrosso

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_IP}/ordercompletedstripe`,
      },
    });


    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmition}
      className="w-80vw min-w-500px self-center shadow-md rounded-lg p-10"
    >
      <div className="border-b-2 mb-6"></div>
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        className="mb-6"
      />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="bg-blue-500 text-white rounded-md py-3 px-4 font-semibold w-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner"></div> : "Paga ora"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div
          id="payment-message"
          className="text-gray-700 text-base pt-3 text-center"
        >
          {message}
        </div>
      )}
    </form>
  );
}
