import React from 'react';
import { Link } from 'react-router-dom';

const ClientServiceComponent = () => {
    return (
        <div className="bg-gray-800 container mx-auto px-4 py-36 text-white font-montserrat">
            <h2 className="text-xl md:text-2xl mb-1 relative">
                Servizio Clienti
                <div className="absolute bottom--4 left-0 w-[100px] h-0.5 bg-red-400 border-t-2 border-b-2 border-red-500 ml-1"></div>
            </h2>
            <p className="md:text-lg mb-1 relative">
                Hai bisogno di assitenza? Il nostro team del servizio clienti è a tua disposizione.
            </p>

            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <Link to={'/productrequest'}>
                    <li className="bg-blue-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                        <h3 className="text-xl">Richiesta prodotto</h3>
                        <p className="mt-2">Non riesci a trovare il tuo prodotto? Compila il seguente modulo per inviare la tua richiesta per uno specifico prodotto</p>
                    </li>
                </Link>
                <Link to={'/orderrequest'}>
                    <li className="bg-green-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                        <h3 className="text-xl">Informazioni sull'ordine</h3>
                        <p className="mt-2">Hai domande sull'ordine effettuato? Clicca qui.</p>
                    </li>
                </Link>
                <Link to={'/otherquestion'}>
                <li className="bg-purple-500 rounded-lg p-4 shadow-lg transform transition duration-500 hover:scale-105">
                    <h3 className="text-xl">Facci una domanda</h3>
                    <p className="mt-2">Hai una richiesta specifica da fare? Clicca qui per scriverci!</p>
                </li>
                </Link>
            </ul>
        </div>
    );
};

export default ClientServiceComponent;
