import React from "react";

export default function FormDataSection({ formData, handleFormChange, handleCodiceFiscaleChange }) {
  return (
    <div className="mb-6">
      <h2 className="text-lg mb-4">Inserisci i dati:</h2>
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          name="nome"
          value={formData.nome}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Nome"
          required // Campo obbligatorio
        />
        <input
          type="text"
          name="cognome"
          value={formData.cognome}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Cognome"
          required // Campo obbligatorio
        />
        <input
          type="text"
          name="codiceFiscale"
          value={formData.codiceFiscale}
          onChange={handleCodiceFiscaleChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Codice Fiscale"
          required // Campo obbligatorio
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Indirizzo email"
          required // Campo obbligatorio
        />
        <input
          type="tel"
          name="telefono"
          value={formData.telefono}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Telefono"
          required
        />
        <input
          type="text"
          name="via"
          value={formData.via}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Via"
          required // Campo obbligatorio
        />
        <input
          type="text"
          name="numeroCivico"
          value={formData.numeroCivico}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Numero Civico"
          required // Campo obbligatorio
        />
        <input
          type="text"
          name="codicePostale"
          value={formData.codicePostale}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Codice Postale"
          required // Campo obbligatorio
        />
        <input
          type="text"
          name="citta"
          value={formData.citta}
          onChange={handleFormChange}
          className="border p-2 rounded-md w-full"
          placeholder="* Città"
          required // Campo obbligatorio
        />
      </div>
    </div>
  );
}
