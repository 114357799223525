import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import IncrementPriceComponent from "../../PaginaProdotto/Product/IncrementPriceComponent";

export default function CartView({ setTotalPrice, setCart, cart, totalPrice, idSession }) {
  const [cartElements, setCartElements] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fillCartView();
  }, [location]);

  async function setPrice(currentPrice) {
    if (typeof currentPrice !== "string") {
        throw new Error("Invalid price format");
    }

    let formattedPrice = currentPrice.replace(",", ".");
    try {
        const response = await axios.get(`${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(formattedPrice).toFixed(2)}`);
        return response.data.incrementedPrice.replace(".", ",");
    } catch (error) {
        console.error('Error fetching incremented price:', error);
        throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
    }
  }

  const fillCartView = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IP}/api/cart/return/${idSession}`);
      const cart = response.data;
      setCart(cart);
      const articleNumbers = Object.keys(cart.positionen).map(
        (key) => cart.positionen[key].artikelnummer
      );
      const amountNumbers = Object.keys(cart.positionen).map(
        (key) => cart.positionen[key].bestellmenge
      );
      let totalAmount = 0;
      const updatedCartElements = [];

      for (let i = 0; i < articleNumbers.length; i++) {
        const articolo = articleNumbers[i];
        const amount = amountNumbers[i];

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_IP}/api/product/${articolo}/${idSession}`
            );

            const updatedResponse = {
                ...response.data,
                bestellmenge: amount,
            };

            // Attendere che la promessa sia risolta e quindi convertire il risultato in float
            const price = await setPrice(updatedResponse.ekpreis.replace(",", "."));
            const formattedPrice = parseFloat(price.replace(",", "."));

            totalAmount += formattedPrice * parseFloat(updatedResponse.bestellmenge);

            updatedCartElements.push(updatedResponse);
        } catch (error) {
            console.error(error);
        }
    }

      setTotalPrice(totalAmount.toFixed(2));
      setCartElements(updatedCartElements);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      {cart.positionen !== undefined ? (
        <>
          <ul className="pt-4 space-y-2">
            {cartElements.map((element, index) => (
              <li key={index} className="border p-4 flex space-x-4">
                <img
                  src={
                    element.thumbnailurl
                      ? element.thumbnailurl
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdT1RrLtX_ow1clmFGZmKvhd2hAwdT4mAkZu4cSXgN&s"
                  }
                  alt="Immagine"
                  className="w-32 h-32 object-contain max-h-32"
                />
                <div className="flex-grow">
                  <h2 className="text-lg font-semibold">
                    {element.artikelbezeichnung}
                  </h2>
                  <p>Quantità: {parseInt(element.bestellmenge)}</p>
                  <p
                    className={`${
                      element.lieferzeit.startsWith("Disponibile")
                        ? "text-green-600"
                        : "text-orange-600/90"
                    }`}
                  >
                    {element.lieferzeit}
                  </p>
                  <p>
                    Prezzo:{" "}
                    
                    {/* {(
                      (<IncrementPriceComponent currentPrice={element.ekpreis}/> * parseInt(element.bestellmenge))
                    )}€{" "}  */}
                    <i>
                      <IncrementPriceComponent currentPrice={element.ekpreis}/>
                      €/unità{" "}
                    </i>
                  </p>
                </div>
              </li>
            ))}
          </ul>
          {}
        </>
      ) : (
        <p className="text-center">Il carrello è vuoto</p>
      )}
    </div>
  );
}
