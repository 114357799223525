import React, { useState, useEffect } from "react";
import axios from "axios";

export default function AdminReturnedItemsPanel() {
  const [returnedItems, setReturnedItems] = useState([]);
  const [newItem, setNewItem] = useState({
    productName: "",
    modelCode: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReturnedItems = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IP}/api/returnedItems`
        );
        setReturnedItems(response.data);
      } catch (error) {
        console.error("Error fetching returned items:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReturnedItems();
  }, []);

  const handleAddItem = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_IP}/api/returnedItems`, newItem);
      setReturnedItems([...returnedItems, response.data]);
      setNewItem({ productName: "", modelCode: "", status: "" }); // Reset form
    } catch (error) {
      console.error("Error adding new item:", error);
    }
  };

  const handleUpdateItem = async (id) => {
    try {
      const itemToUpdate = returnedItems.find((item) => item.id === id);
      const response = await axios.put(
        `${process.env.REACT_APP_IP}/api/returnedItems/${id}`,
        itemToUpdate
      );

      // Aggiorna lo stato con l'elemento modificato
      setReturnedItems(
        returnedItems.map((item) =>
          item.id === id ? { ...item, ...response.data } : item
        )
      );
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_IP}/api/returnedItems/${id}`);
      setReturnedItems(returnedItems.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    if (id) {
      setReturnedItems(
        returnedItems.map((item) =>
          item.id === id ? { ...item, [name]: value } : item
        )
      );
    } else {
      setNewItem({ ...newItem, [name]: value });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">
        Gestione Articoli Restituiti
      </h2>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="spinner"></div>{" "}
          {/* Utilizza una classe CSS per un effetto spinner */}
        </div>
      ) : (
        <>
          <div className="mb-6 bg-white shadow-md rounded p-6">
            <h3 className="text-xl font-semibold mb-4">
              Aggiungi Articolo Restituito
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <input
                type="text"
                name="productName"
                placeholder="Nome Prodotto"
                className="border rounded p-2 w-full"
                value={newItem.productName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="modelCode"
                placeholder="Codice Modello"
                className="border rounded p-2 w-full"
                value={newItem.modelCode}
                onChange={handleChange}
              />
              <input
                type="text"
                name="status"
                placeholder="Stato Prodotto"
                className="border rounded p-2 w-full"
                value={newItem.status}
                onChange={handleChange}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleAddItem}
            >
              Aggiungi
            </button>
          </div>

          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">
                  Descrizione Prodotto
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">
                  Codice Modello
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">
                  Stato Prodotto
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">
                  Azioni
                </th>
              </tr>
            </thead>
            <tbody>
              {returnedItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <input
                      type="text"
                      name="productName"
                      className="input-field"
                      value={item.productName}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <input
                      type="text"
                      name="modelCode"
                      className="input-field"
                      value={item.modelCode}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <input
                      type="text"
                      name="status"
                      className="input-field"
                      value={item.status}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      className="text-blue-500 hover:text-blue-800 mr-2"
                      onClick={() => handleUpdateItem(item.id)}
                    >
                      Modifica
                    </button>
                    <button
                      className="text-red-500 hover:text-red-800"
                      onClick={() => handleDelete(item.id)}
                    >
                      Elimina
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
