import { Link } from "react-router-dom";
import axios from "axios";

import QuantityEditor from "./QuantityEditor";
import IncrementPriceComponent from "../../PaginaProdotto/Product/IncrementPriceComponent";
import { useEffect, useState } from "react";

export default function CartElement({
  element,
  index,
  setCart,
  setTriggerEffect,
  triggerEffect,
  idSession,
}) {
  const [amountPrice, setAmountPrice] = useState(element.bestellmenge);

  useEffect(() => {
    sistemaPrezzo();
  }, [element.bestellmenge]);

  const sistemaPrezzo = async () => {
    // Supponiamo che setPrice restituisca una stringa, es: "10.50"
    const value = await setPrice(element.ekpreis.replace(",", ".")); // "10.50"

    // Converti entrambi i valori in float prima della moltiplicazione
    const result = parseFloat(value.replace(",",".")) * parseFloat(element.bestellmenge);

    // console.log("Value: " + parseFloat(value.replace(",",".")) + " * Quantità: " + parseFloat(element.bestellmenge) + " = " + result); // Ora dovrebbe stampare un float
    setAmountPrice(result.toFixed(2).toString().replace(".",","));
  };

  const onQuantityChange = async (quantity) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/update/${element.artikelnummer}/${quantity}/${element.ekpreis}/${idSession}`
      );

      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/cart/return/${idSession}`
      );
      setCart(response.data);

      setTriggerEffect(!triggerEffect);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const onRemove = async () => {
    try {
      const zero = "0";
      await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/update/${element.artikelnummer}/${zero}/${element.ekpreis}/${idSession}`
      );

      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/cart/return/${idSession}`
      );
      setCart(response.data);

      setTriggerEffect(!triggerEffect);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  async function setPrice(currentPrice) {
    if (typeof currentPrice !== "string") {
      throw new Error("Invalid price format");
    }

    let formattedPrice = currentPrice.replace(",", ".");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IP}/api/prices/price/${parseFloat(
          formattedPrice
        ).toFixed(2)}`
      );
      return response.data.incrementedPrice.replace(".", ",");
    } catch (error) {
      console.error("Error fetching incremented price:", error);
      throw error; // Rilancia l'errore per consentire la gestione dell'errore nel chiamante
    }
  }

  return (
    <>
      <Link to={`../product/${element.artikelnummer}`}>
        <img
          src={
            element.thumbnailurl
              ? element.thumbnailurl
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdT1RrLtX_ow1clmFGZmKvhd2hAwdT4mAkZu4cSXgN&s"
          }
          alt="Immagine"
          className="w-32 h-32 object-contain max-h-32"
        />
      </Link>

      <div className="flex-grow">
        <Link to={`../product/${element.artikelnummer}`}>
          <h2 className="text-lg font-semibold">
            {element.artikelbezeichnung}
          </h2>
        </Link>

        <p>Quantità: {parseInt(element.bestellmenge)}</p>
        <p
          className={`${
            element.lieferzeit.startsWith("Disponibile")
              ? "text-green-600"
              : "text-orange-600/90"
          }`}
        >
          {element.lieferzeit}
        </p>

        <p>
          Prezzo: {amountPrice}€{" "}
          <i>
            (
            <IncrementPriceComponent currentPrice={element.ekpreis} />
            €/unità){" "}
          </i>
        </p>
        <QuantityEditor
          initialQuantity={parseInt(element.bestellmenge)}
          onQuantityChange={onQuantityChange}
          onRemove={onRemove}
        />
      </div>
    </>
  );
}
