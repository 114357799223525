import React from "react";
import sfondo from "../../../../immagini/immagini_varie/sfondoCustom.webp";
import codemodel from "../../../../immagini/immagini_varie/character.jpg";
import assistenza from "../../../../immagini/immagini_varie/assistenza.jpg";
import { Link } from "react-router-dom";
import CustomInfoPoint from "./Navbar/CustomInfoPoint";

export default function CustomLandingPage({ idSession, cart }) {
  return (
    <div>
      <div className="bg-gray-800 pt-8">
        <img
          src={sfondo}
          alt="Immagine della sfera blu"
          width="auto"
          height="auto"
          className="bg-cover bg-center object-cover m-auto"
        />
        <div className="flex gap-2">
          <div className="flex flex-col px-4">
            <img
              src={codemodel}
              alt="Immagine della sfera blu"
              width={1000}
              height={1}
              className="bg-cover bg-center object-cover rounded-3xl"
            />
            <div className="text-center">
              <CustomInfoPoint />
            </div>
          </div>
          <div className="flex flex-col px-4">
            <Link to="/clientservice">
              <img
                src={assistenza}
                alt="Immagine della sfera blu"
                width={1000}
                height={1}
                className="bg-cover bg-center object-cover rounded-3xl"
              />
              <p className="text-center font-montserrat text-lg text-blue-500">
                Servizio Clienti
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
