import React, { useEffect, useState } from "react";
import SearchBar from "../../Pages/Homepage/HomepageComponent/Navbar/SearchBar";
import Cart from "../../Pages/Homepage/HomepageComponent/Navbar/Cart";
import { Link } from "react-router-dom";
import image from "../../../immagini/immagini_varie/logo.jpg";
import Logo from "../../Pages/Homepage/HomepageComponent/Navbar/Logo";
import { FcInfo } from "react-icons/fc";
import ComeFunziona from "./ComeFunziona";
import InfoPoint from "./InfoPoint";
import TopNavbar from "./TopNavbar";

export default function Navbar({ cart, idSession }) {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 100) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div>
      <nav
        className={`fixed top-0 md:bg-gray-800 bg-gray-900 w-full z-10 md:pr-0 pr-12 md:pl-0 pl-12 navbar-transition ${
          showNavbar ? "" : "navbar-hidden"
        }`}
      >
        <TopNavbar />
        <div className="container mx-auto flex items-center pt-2 justify-between">
          {/* Logo visibile solo su schermi non mobili (md e superiori) */}
          <Link to="/">
            <img
              className="w-52"
              src={require("../../../immagini/logo/logo2.png")}
            />
          </Link>
          {/* Barra di ricerca sempre visibile */}
          <div className="hidden grow md:block">
            <SearchBar idSession={idSession} />
          </div>
          {/* Icona del carrello visibile solo su schermi mobili (md e inferiori) */}
          <div>
            <Cart className="text-white" cart={cart} idSession={idSession} />
          </div>
        </div>

        <div className="flex bg-gray-800 py-3 px-96 space-x-12 justify-center items-center">
          <div className="flex items-center gap-2 text-blue-500">
            <FcInfo className="w-12 h-12 sm:w-8 sm:h-8" /> <InfoPoint />
          </div>
          <div className="flex items-center gap-2">
            <FcInfo className="w-12 h-12 sm:w-8 sm:h-8" /> <ComeFunziona />
          </div>
        </div>
      </nav>
      <div className="mb-40"></div>
    </div>
  );
}
