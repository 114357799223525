import React, { useEffect, useState } from "react";
import axios from "axios";
import TopNavbar from "../WebParts/TopNavbar";
import CustomNavbar from "../WebParts/CustomNavbar";
import Navbar from "../WebParts/Navbar";
import { Link, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Footer from "../WebParts/Footer";
import TripleSearchBar from "../Homepage/HomepageComponent/TripleSearchBar";

export default function BrandPage({ idSession, cart }) {
  const { brand } = useParams();
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [maxVisibleModels, setMaxVisibleModels] = useState(50);
  
  useEffect(() => {
    const fetchModels = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IP}/api/models/${brand}`
        );
        setModels(response.data);
        setFilteredModels(response.data);
        if (response.data.length === 0) {
          setError(`Nessun modello trovato per il brand: ${brand}`);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
        setError("Errore nel recuperare i modelli.");
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, [brand]);

  useEffect(() => {
    const filtered = models.filter((model) =>
      model.model_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredModels(filtered);
  }, [searchTerm, models]);

  return (
    <>
      <TopNavbar />
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>

      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">
          Prodotti associati al brand: {brand}
        </h2>

        <input
          type="text"
          className="mb-4 p-2 w-full border border-gray-300 rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
          placeholder="Cerca modello..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {loading ? (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75 z-50">
            <div className="flex flex-col items-center">
              <BeatLoader color="#4A90E2" />
            </div>
          </div>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <ul className="space-y-2">
            {filteredModels.slice(0, maxVisibleModels).map((model, index) => (
              <li key={index} className="list-none hover:bg-blue-100 hover:border-l-4 hover:border-l-blue-800">
                <Link
                  to={`/productSearch/${model.model_name.replace("/", "")}/${brand}`}
                  className="text-blue-500 hover:text-blue-700 md:pl-2 transition-colors duration-150 ease-in-out"
                >
                  {model.model_name}
                </Link>
              </li>
            ))}
          </ul>
        )}

        {filteredModels.length > maxVisibleModels && (
          <button
            className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-150 ease-in-out"
            onClick={() => setMaxVisibleModels(maxVisibleModels + 50)}
          >
            Carica più modelli
          </button>
        )}
      </div>

      <div className="md:px-24 px-4">
        <TripleSearchBar />
      </div>

      <Footer />
    </>
  );

}
