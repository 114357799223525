import React, { useState } from 'react';
import Stripe from '../MetodiDiPagamento/Stripe';
import { BiCreditCard } from 'react-icons/bi';
import { PiPaypalLogoBold } from 'react-icons/pi';
import { PiBankBold } from 'react-icons/pi';
import PayPalCheckoutButton from '../MetodiDiPagamento/PayPalCheckoutButton';

export default function PaymentMethodSection({ paymentMethod, totalPrice, shippingPrice, setPaymentMethod, handleSubmit, email, numeroOrdine, handleSubmit2 }) {

  const totalPriceString = (parseFloat(totalPrice) + parseFloat(shippingPrice)).toFixed(2).toString();
  const formattedTotalPrice = totalPriceString.replace(',', '.');

  const product = {
    description: "Prodotti IngrossoRicambi",
    price: formattedTotalPrice,
  };


  return (
    <div className="mb-6">
      <h2 className="text-lg font-semibold mb-2">Metodi di Pagamento</h2>

      <div className="mb-2">
        Seleziona il metodo di pagamento:
      </div>

      <div className="flex flex-col md:flex-row md:space-x-4">
        <button
          className={`flex p-4 border rounded items-center text-white bg-green-500`}
          onClick={() => setPaymentMethod("Carta di Credito")}
        >
          <BiCreditCard className="mr-2" size={28} /> Carta di Credito
        </button>
        <button
          className={`flex p-4 border rounded items-center text-white bg-blue-500`}
          onClick={() => setPaymentMethod("PayPal")}
        >
          <PiPaypalLogoBold className="mr-2" size={28} /> PayPal
        </button>
        <button
          className={`flex p-4 border rounded items-center bg-gray-300`}
          onClick={() => setPaymentMethod("Bonifico")}
        >
          <PiBankBold className="mr-2" size={28} /> Bonifico
        </button>
      </div>

      {(totalPrice !== 0 && paymentMethod === "Carta di Credito") && (
        <div className="mt-4">
          <Stripe totalPrice={(parseFloat(totalPrice) + parseFloat(shippingPrice)).toFixed(2)} handleSubmit2={handleSubmit2} email={email} numeroOrdine={numeroOrdine} prezzoTotale={totalPrice}/>
        </div>
      )}

      {paymentMethod === "PayPal" && (
        <div className='pt-4'><PayPalCheckoutButton product={product} handleSubmit={handleSubmit}/></div>
      )}

      {paymentMethod === "Bonifico" && (
        <div className='p-4 border-2'>
          <p className='pb-12'>Con il <b>bonifico bancario</b>, il tuo ordine sarà processato direttamente dopo il pagamento, riceverai una conferma di pagamento. Si prega di utilizzare le informazioni qui sotto.</p>

          <table className="table-auto w-full ">
            <tbody>
              <tr>
                <td className="py-2">Importo:</td>
                <td>{(parseFloat(totalPrice) + parseFloat(shippingPrice)).toFixed(2).toString().replace('.', ',')}€</td>
              </tr>
              <tr>
                <td className="py-2">Descrizione:</td>
                <td>Ordine IngrossoRicambi</td>
              </tr>
              <tr>
                <td className="py-2">IBAN:</td>
                <td className='text-sm'>IT86D0817835222000006170776</td>
              </tr>
              <tr>
                <td className="py-2">Banca:</td>
                <td>BPER BANCA</td>
              </tr>
              <tr>
                <td className="py-2">Intestato a:</td>
                <td>IngrossoRicambi di SALTORI BENEDETTA</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

    </div>
  );
};
