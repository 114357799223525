import React, { useState } from "react";

const Modal = ({ isOpen, onClose, title, content }) => {
  const closeModal = () => {
    if (isOpen) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
      onClick={closeModal}
    >
      <div
        className="bg-white w-full max-w-screen-md p-4 rounded-lg border-2 border-blue-500 shadow-md overflow-auto"
        onClick={(e) => e.stopPropagation()} // Impedisci la chiusura se si clicca sulla finestra modale stessa
      >
        <h2 className="text-lg mb-2">{title}</h2>
        <div className="max-h-96 overflow-y-auto">{/* Imposta un'altezza massima e abilita la scorrevolezza verticale */}
          <p>{content}</p>
        </div>
        <button
          onClick={onClose}
          className="mt-4 p-2 px-4 bg-blue-500 hover:bg-red-500 rounded-md"
        >
          Chiudi
        </button>
      </div>
    </div>
  );
};

export default Modal;
