import React, { useState } from "react";
import axios from "axios";
import Footer from "../WebParts/Footer";
import Navbar from "../WebParts/Navbar";
import CustomNavbar from "../WebParts/CustomNavbar";

export default function OtherQuestion({ cart, setCart, idSession }) {
  const [formData, setFormData] = useState({
    email: "",
    message: "", // Nuovo campo per il messaggio opzionale
  });

  const [notification, setNotification] = useState({
    message: "",
    type: "", // Esempio: "success", "error", "warning", ecc.
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Se il campo message è vuoto, assegna "non definito"
    if (formData.message.trim() === "") {
      formData.message = "non definito";
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IP}/api/cart/orderrequest/1/1/${formData.email}/1/1/${formData.message}`
      );
      // console.log("Risposta dal server:", response.data);
      // console.log("Riuscito: ", response.data.success);

      if (response.data.success) {
        // Imposta un messaggio di notifica di successo
        setNotification({
          message: "Email inviata con successo!",
          type: "success",
        });

        // Reimposta i campi del form a valori vuoti
        setFormData({
          email: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Errore nella richiesta HTTP:", error);

      // Imposta un messaggio di notifica di errore
      setNotification({
        message: "Si è verificato un errore durante l'invio dell'email.",
        type: "error",
      });
    }
  };

  return (
    <>
      {/* Visualizzazione su smartphone */}
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>

      {/* Visualizzazione su computer */}
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>
      <div className="bg-gray-100 py-12">
        <div className="max-w-3xl mx-auto bg-white p-6 md:p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl md:text-4xl mb-4 text-center font-semibold">
            Devi farci una domanda?
          </h1>
          <p className="text-gray-800 text-center mb-6 md:mb-8">
            Compila il seguente modulo e scrivici la tua richiesta
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="email"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                pattern="[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,4}"
                className="form-input w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
              <small className="text-gray-600">
                Inserisci un indirizzo email valido.
              </small>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="message"
              >
                Messaggio <span className="text-red-500">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                rows="4"
                className="form-textarea w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-indigo-500 text-white font-bold py-3 md:py-4 px-6 md:px-8 rounded-lg hover-bg-indigo-600 focus:outline-none focus:shadow-outline"
              >
                Invia Richiesta
              </button>
            </div>
          </form>

          {notification.message && (
            <div
              className={`mt-4 p-4 text-white text-center ${
                notification.type === "success"
                  ? "bg-green-500"
                  : notification.type === "error"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              {notification.message}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
