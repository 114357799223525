import { useEffect, useState } from "react";
import { FiShoppingBag } from "react-icons/fi";
import axios from "axios";
import { Link } from "react-router-dom";


export default function Cart({ cart, idSession }) {
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    
    if (cart === undefined || !isEmptyObject(cart)) {
      handleLogoNumber();
    }
  }, [cart]);
  
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }  

  const handleLogoNumber = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IP}/api/cart/return/orderNumber/${idSession}`);
      setCartQuantity(response.data)
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const handleClick = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IP}/api/cart/return/${idSession}`);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  return (
    <>
      <Link to="/checkout/cart" onClick={handleClick} className="relative rounded-2xl bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 p-2 inline-block">
        <FiShoppingBag size={36} className="w-8 h-8 text-white" /> {/* Aggiunta classe "text-white" per lo sfondo scuro */}
        {cartQuantity > 0 && (
          <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-white rounded-full text-black font-bold text-sm w-6 h-6 flex items-center justify-center border-2 border-black"> {/* Migliorato il bordo e aggiunto uno sfondo scuro */}
            {cartQuantity}
          </span>
        )}
      </Link>
    </>
  );

  
}
