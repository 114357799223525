import React from "react";
import bg from "../../../../immagini/immagini_varie/sfondo.jpg";
import freccia from "../../../../immagini/immagini_varie/image-removebg-preview.png";
import omino from "../../../../immagini/immagini_varie/omino.png";
import omino1 from "../../../../immagini/immagini_varie/omino2_auto_x2.jpg";
import assistenza from "../../../../immagini/immagini_varie/assistenza.jpg";
import codemodel from "../../../../immagini/immagini_varie/character.jpg";
import lente from "../../../../immagini/immagini_varie/lente.jpg";
import ball from "../../../../immagini/immagini_varie/ball.png";
import TopNavbar from "../../WebParts/TopNavbar";
import SearchBar from "./Navbar/SearchBar";
import Cart from "./Navbar/Cart";
import { Link } from "react-router-dom";
import ComeFunziona from "../../WebParts/ComeFunziona";
import InfoPoint from "../../WebParts/InfoPoint";
import TripleSearchBar from "./TripleSearchBar";
import TripleSearchBarCustom from "./TripleSearchBarCustom";

export default function LandingPage({ idSession, cart }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-10 grid-rows-10 md:grid-rows-7 h-screen">
      <div className="bg-gray-800 col-span-1 md:col-span-10 row-span-1 md:row-span-1">
        <TopNavbar />
        <div className="flex gap-4 py-4 px-12">
          <Link to="/">
            <img
              className="w-20 md:w-40"
              src={require("../../../../immagini/logo/logo2.png")}
              alt="Logo"
            />
          </Link>
          <SearchBar idSession={idSession} />
          <Cart className="text-white" cart={cart} idSession={idSession} />
        </div>
      </div>

      <div className="bg-gray-800 md:col-span-5 md:row-span-2 pl-10 flex-col py-8 text-center md:text-left">
        <div className="text-7xl text-white">
          <div className="flex justify-start font-montserrat">
            <p>Ingrosso</p>
            <p className="text-blue-500">Ricambi</p>
            <p>.</p>
          </div>
          <p className="text-6xl text-gray-200 font-montserrat">
            Ingrosso specializzato, rivolto a tecnici e riparatori privati.
          </p>
        </div>
        <div className="pt-4 text-blue-500">
          <ComeFunziona />
          <InfoPoint />
          <Link to={"/productrequest"}>
            <p className="text-blue-500 hover:underline">
              Non trovi il tuo ricambio?
            </p>
          </Link>
        </div>
      </div>

      {/* <div className="bg-gray-800 col-span-1 md:col-span-3 row-span-3 md:row-span-2 relative">
        <img
          src={ball}
          alt="Immagine della sfera blu"
          className="w-40 md:w-60 bg-cover bg-center object-cover m-auto"
        />
      </div> */}

      <div className="bg-gray-800 col-span-1 md:col-span-5 row-span-4 md:row-span-5 items-center flex justify-center">
        {/* <img
          src={omino1}
          alt="Omino a destra della landing page"
          className="w-10/12 h-full bg-cover object-cover"
        /> */}
        <TripleSearchBarCustom />
      </div>

      {/* <div className='col-span-6 pl-10 row-span-2'>
        <TripleSearchBar/>
      </div> */}

      <div className="bg-gray-800 col-span-5 row-span-2 flex gap-12 items-center pl-10 justify-left flex-row">
        {/* Questo div diventa nascosto su schermi più piccoli di 'md' */}
        <div className="transform transition duration-500 hover:scale-105 hidden md:hidden">
          <div className="w-64 h-64">
            <img
              src={codemodel}
              alt="Immagine dell'omino che cerca il codice modello"
              className="object-cover h-full w-full rounded-t-2xl"
            />
          </div>
          <p className="w-64 p-4 border bg-white text-center rounded-b-2xl">
            Inserisci il codice modello del tuo apparecchio
          </p>
        </div>

        <Link
          to={"/productrequest"}
          className="transform transition duration-500 hover:scale-105"
        >
          <div className="w-64 h-64">
            <img
              src={lente}
              alt="Immagine della lente"
              className="object-cover h-full w-full rounded-t-2xl"
            />
          </div>
          <p className="w-64 p-4 border bg-white text-center rounded-b-2xl">
            Hai bisogno di fare una richiesta prodotto?
          </p>
        </Link>

        <Link
          to={"/clientservice"}
          className="transform transition duration-500 hover:scale-105"
        >
          <div className="w-64 h-64">
            <img
              src={assistenza}
              alt="Immagine del tizio del servizio clienti"
              className="object-cover h-full w-full rounded-t-2xl"
            />
          </div>
          <p className="w-64 p-4 border bg-white text-center rounded-b-2xl">
            Hai bisogno di assistenza? Contattaci
          </p>
        </Link>
      </div>
    </div>
  );
}
