import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";

export default function SearchBar({ idSession }) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [applianceArray, setApplianceArray] = useState([]);
  const [articoliArray, setArticoliArray] = useState([]);
  const [categorieArray, setCategorieArray] = useState([]);
  const [css, setCss] = useState("");
  const [apparecchiTrovati, setApparecchiTrovati] = useState([]);
  const [articoliTrovati, setArticoliTrovati] = useState([]);
  const [categorieTrovati, setCategorieTrovati] = useState([]);
  const [clickedItemType, setClickedItemType] = useState(null);
  const [clicked, setClicked] = useState("");

  const handleChange = async (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);

    if (newInputValue.length >= 3) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IP}/api/articles/${newInputValue}/${idSession}`
        );

        setCss(convertToJSX(response.data.suggests.css));
        setArticoliTrovati(response.data.suggests.artikeltreffer);
        setApparecchiTrovati(response.data.suggests.geraetetreffer);
        setCategorieTrovati(response.data.suggests.vgruppentreffer);

        setApplianceArray([]);
        setArticoliArray([]);
        setCategorieArray([]);

        if (response.data.suggests.geraetetreffer) {
          const geraetebezeichnungArray =
            response.data.suggests.geraetetreffer.map(
              (item) => item.geraetebezeichnung
            );
          setApplianceArray(geraetebezeichnungArray);
        }

        if (response.data.suggests.artikeltreffer) {
          const artikelbezeichnungArray =
            response.data.suggests.artikeltreffer.map(
              (item) => item.artikelbezeichnung
            );
          setArticoliArray(artikelbezeichnungArray);
        }

        if (response.data.suggests.vgruppentreffer) {
          const vgruppennameArray = response.data.suggests.vgruppentreffer.map(
            (item) => item.vgruppenname
          );
          setCategorieArray(vgruppennameArray);
        }
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    } else {
      setCss("");
      setApplianceArray([]);
      setArticoliArray([]);
      setCategorieArray([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Se il tasto premuto è "Enter", chiama handleUpdate
      handleUpdate();
    }
  };

  function convertToJSX(cssString) {
    const cleanCss = cssString
      .replace('<style type="text/css">', "")
      .replace("</style>", "");

    return (
      <style type="text/css">
        {`
                  ${cleanCss}
                `}
      </style>
    );
  }

  const handleUpdate = () => {
    if (!inputValue) {
      // console.log("Invalid input");
    } else {
      const cleanInputValue = inputValue.replace('#', '');
      navigate(`/product/${cleanInputValue}`);
      setInputValue("");
      setApplianceArray([]);
    }
  };

  const combinedResults = [
    { label: "Articoli", items: articoliArray },
    { label: "Apparecchi", items: applianceArray },
    { label: "Categorie", items: categorieArray },
  ];

  const handleItemClick = (itemName, itemType) => {
    switch (itemType) {
      case "Articoli":
        const foundArticolo = articoliTrovati.find(
          (articolo) => articolo.artikelbezeichnung === itemName
        );
        if (foundArticolo) {
          navigate(`/product/${foundArticolo.artikelnummer}`);
          // console.log(
          //   `Artikelnummer associato: ${foundArticolo.artikelnummer}`
          // );
        } else {
          // console.log("Articolo non trovato");
        }
        break;

      case "Apparecchi":
        const foundApparecchio = apparecchiTrovati.find(
          (apparecchio) => apparecchio.geraetebezeichnung === itemName
        );
        if (foundApparecchio) {
          navigate(`/search/filters/${foundApparecchio.geraeteid}/1`);
          // console.log(`Geraeteid associato: ${foundApparecchio.geraeteid}`);
        } else {
          // console.log("Apparecchio non trovato");
        }
        break;

      case "Categorie":
        const foundCategoria = categorieTrovati.find(
          (categoria) => categoria.vgruppenname === itemName
        );
        if (foundCategoria) {
          navigate(
            `/product/filters/${inputValue}/${foundCategoria.vgruppenid}/1`
          );
          // console.log(`Vgruppenid associato: ${foundCategoria.vgruppenid}`);
          // console.log(`Testo della barra di ricerca: ${inputValue}`);
        } else {
          // console.log("Categoria non trovata");
        }
        break;

      default:
        // console.log("Tipo di elemento non gestito");
    }

    setClickedItemType(itemType);
  };

  return (
    <div className="flex-grow relative w-full px-4">
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
        <input
          className="h-12 pl-4 pr-3 py-6 text-m text-gray-900 border border-gray-300 rounded-full w-full"
          type="text"
          placeholder="Inserisci il codice modello..."
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      </div>
      <button
        className="absolute top-1 right-0 h-10/12 px-3 mr-6 py-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-full text-sm"
        onClick={handleUpdate}
      >
        <AiOutlineSearch size={24} />
      </button>

      {combinedResults.length > 0 && (
        <div className="absolute top-14 left-0 z-10 bg-white rounded-lg mt-2 w-full md:w-96 max-h-96 overflow-y-auto">
          {combinedResults.map((resultGroup, groupIndex) => (
            <div key={groupIndex}>
              {resultGroup.items.length > 0 && (
                <>
                  <p className="px-4 py-2 font-bold text-gray-600">
                    {resultGroup.label}
                  </p>
                  {resultGroup.items.map((result, index) => (
                    <p
                      key={index}
                      className="px-4 py-2 border hover:bg-blue-100 cursor-pointer"
                      onClick={() => {
                        handleItemClick(result, resultGroup.label);
                        setApplianceArray([]);
                        setArticoliArray([]);
                        setCategorieArray([]);
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: result }}
                        className="mb-1"
                      />
                    </p>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
      )}

      {css}
    </div>
  );
}
