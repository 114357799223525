import { BiBulb } from "react-icons/bi";
import React, { useState, useEffect } from 'react';
import { IoLeafOutline } from 'react-icons/io5';
import { PiPackageDuotone } from 'react-icons/pi';
import { CiDeliveryTruck } from 'react-icons/ci';
import { FaStar } from 'react-icons/fa';


export default function TopNavbarCustom() {
    const titles = ["Spedizione rapida garantita", "Conviene per te e per l'ambiente", "15.000.00 di ricambi disponibili", " "];
    const icons = [<CiDeliveryTruck style={{color:"white"}}/>, <IoLeafOutline style={{color:"white"}}/>, <PiPackageDuotone style={{color:"white"}}/>,  <><BiBulb style={{color:"white"}}/><p className='pl-2 text-xs md:text-sm text-white'>Garanzia di qualità</p></>];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
        }, 5000); // Cambia il titolo ogni 3 secondi

        // Rimuovi la classe di animazione quando il titolo cambia
        const titleElement = document.querySelector('.justify-center');
        titleElement.classList.remove('appear-animation-right');

        setTimeout(() => {
            // Riapplica la classe di animazione dopo un breve ritardo
            titleElement.classList.add('appear-animation-right');
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, [currentIndex, titles]);

    return (
        <div className="bg-blue-600 py-2 md:px-4 px-2">
            <div className="container flex items-center justify-center">
                <div className="text-center max-w-md">
                    <div className="flex items-center">
                        {icons[currentIndex] /* Mostra l'icona corrispondente al titolo */}
                        <p className="text-xs md:text-sm text-white ml-2">{titles[currentIndex]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
