import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AdminLogin({ adminIsAuthenticated, setAdminIsAuthenticated }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Qui dovresti effettuare la logica di autenticazione, ad esempio facendo una richiesta a un server.
    // Puoi utilizzare librerie come axios per effettuare richieste HTTP.
    if (username === 'admin' && password === 'federico') {
      // L'accesso è riuscito, reindirizza l'amministratore al pannello.
      // Puoi utilizzare React Router per la navigazione tra le pagine.
      // Esempio di reindirizzamento: history.push('/dashboard');
      // console.log('Accesso riuscito!');
      setAdminIsAuthenticated(true);
      navigate(`/admin`);
    } else {
      // L'accesso non è riuscito, puoi mostrare un messaggio di errore.
      // console.log('Accesso fallito. Nome utente o password errati.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96">
        <h2 className="text-2xl mb-6 text-center font-semibold">Accesso Amministratore</h2>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
            Nome utente:
          </label>
          <input
            type="text"
            id="username"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
            Password:
          </label>
          <input
            type="password"
            id="password"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-center">
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleLogin}
          >
            Accedi
          </button>
        </div>
      </div>
    </div>
  );
}